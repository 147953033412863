import { ListItem, TextField, useMediaQuery } from "@material-ui/core"
import React, { useContext, useRef, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import GoogleMap from "../../../GoogleMap/GoogleMapContainer/GoogleMap"
import Hours from "./Hours/Hours"
import PassengerQuantity from "./PassengerQuantity/PassengerQuantity"
import Carousel, { consts } from "react-elastic-carousel"
import SafetySeat from "./SafetySeat/SafetySeat"
import Luggage from "./Luggage/Luggage"
import styles from "./AdressFormStyles/AdressForm.module.scss"
import "./AdressFormStyles/AdressFormDatePickerSeparatedStyles.scss"
import { Switch } from "../../../Helpers/Switch/Switch"
import "./AdressFormStyles/AdressFormCarousel.scss"
import CalendarPicker from "@mui/lab/CalendarPicker"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { Modal } from "../../../Helpers/Modal/Modal"
import ThemeContext from "../../../../context"
import {
  ClockIcon,
  DateIcon,
  HourlyIcon,
  MeetAndGreetIcon,
  MeetAndGreetIconWhite,
  PlaneIcon,
  SafetySeatIcon,
  Ticket,
} from "../../../../assets/icons"
import Autocomplete from "@mui/material/Autocomplete"
import styled from "styled-components"
import MapStyles from "../../../GoogleMap/mapStyles"
import { GoogleApiWrapper, Map, Marker } from "google-maps-react"
import ReCAPTCHA from "react-google-recaptcha"

import { CSSTransition, TransitionGroup } from "react-transition-group"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import { AddLocIcon, DeleteLocIcon } from "../../../../assets/icons"

{
  /*компонента перед экспортом обернута в react.memo*/
}

const AdressFormwithoutReactMemo = ({
  next,
  carTypes,
  pageSize,
  getCompanyCars,
  setFormData,
  formData,
  hourlyAndSeatsRedux,
  setHourlyRedux,
  setGateMeetingRedux,
  setReturnGateMeetingRedux,
  gateMeeting,
  returnGateMeeting,
  backgroundScrollStopForTimePicker,
  setBackgroundScrollStopForTimePicker,
  resetInputs,
  setDateForDefaultValue,
  setTimeForDefaultValue,
  setTimeForDefaultValueAMPM,
  setTimeForDefaultValueAlignment,
  setPassengersQuantityForBackStep,
  isBoosterSeatExistOnBackend,
  isSafetySeatExistOnBackend,
  airlines,
  alignment,
  bookingType,
  boosterSeat,
  carSelectionID,
  childSafetySeat,
  destinations,
  flightNumber,
  handleChangeAMPM,
  handleClick,
  handleSubmit,
  hourly,
  hoursAddressForm,
  isAirline,
  luggage,
  methods,
  myArrow,
  onSubmit,
  passengers,
  redBorderOnAirlines,
  redBorderOnSubmit,
  redBorderOnSubmit2,
  redBorderOnSubmitForCarType,
  redBorderOnSubmitForDate,
  redBorderOnSubmitForPassengers,
  redBorderOnSubmitForTime,
  redBorderOnSubmitForTime2,
  redBorderOnSubmitForTime3,
  redBorderOnSubmitForTime4,
  redBorderOnSubmitForReturnDate,
  redBorderOnSubmitForReturnTime,
  redBorderOnSubmitForReturnTime2,
  redBorderOnSubmitForReturnTime3,
  redBorderOnSubmitForReturnTime4,
  safetySeat,
  setAirlineId,
  setReturnAirlineId,
  setBoosterSeat,
  setChildSafetySeat,
  setDestinations,
  setFlightNumber,
  setHourly,
  setHoursAddressForm,
  setIsAirportPickupIncludedLocalState,
  setReturnIsAirportPickupIncludedLocalState,
  setIsGateMeeting,
  setLuggage,
  setPassengers,
  setSafetySeat,
  date,
  setDate,
  showDatePickerCalendarModal,
  setShowDatePickerCalendarModal,
  showReturnDatePickerCalendarModal,
  setShowReturnDatePickerCalendarModal,
  AMPM,
  register,
  control,
  fetchAirlines,
  extractAirlineId,
  airlineName,
  time,
  setTime,
  setShowCarsWithSafetySeat,
  isGateMeeting,

  isReturnGateMeeting,
  setIsReturnGateMeeting,
  redBorderOnSubmitForHours,
  setHoursRedux,
  hoursCount,
  setSafetySeatCount,
  setBoosterSeatCount,
  showRecaptcha,
  setShowRecaptcha,
  returnDateLocal,
  setReturnDateLocal,
  returnTimeLocal,
  setReturnTimeLocal,
  setIsRoundTrip,
  roundTripSwitch,
  setRoundTripSwitch,
  setUseHookState,
  handleChangeAddress,
  handleSelect,
  addEndPoint,
  removeEndPoint,
  setDestinationsIcons,
  getDestinationsIcons,
  returnDestinations,
  setReturnDestinations,
  setReturnDate,
  setReturnTime,
  setReturnTimeAMPM,
  setReturnTimeAMPMAlignment,
  handleChangeReturnAMPM,
  returnAMPM,
  setReturnAMPM,
  isReturnAirline,
  returnFlightNumber,
  setReturnFlightNumber,
  setIsReturnAirportPickupIncludedRedux,
  loadingAirlines,
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width:1024px)")

  const {
    ThemeProviderAppBackgroundColor,
    fontColor,
    borderRadiuses,
    carsTypeColor,
    hoverColor,
    iconsColor,
    backAndNextButtonsColor,
    backAndNextButtonsFontColor,
    backAndNextButtonsBorderColor,
    innerTextOnHover,
    inputsFontColor,
    inputsBackground,
    borderRadiusesForInnerElements,
    borderRadiusesForWholeApp,
    borderColorForInnerElements,
    borderColorForOuterApp,
    BackgroundImage,
    mapBackground,
    AMPMHoverBackgroundColor,
    AMPMHoverFontColor,
  } = useContext(ThemeContext)

  const [card, setCard] = useState()
  const inputCard = useRef()

  const inputReturnTimeRef = useRef()

  const startsWithTwo = time[0] === "2"

  const handleChange = () => {
    const timeNumberAfterColon = ":"

    const setZeroOrNot = (timeValue1) => {
      if (timeValue1 > 1) {
        return 0
      }
      return timeValue1
    }
    const timeValue = inputCard.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})/)
    inputCard.current.value = timeValue[4]
      ? `${setZeroOrNot(timeValue[1])}${timeValue[2]}${
          (timeValue[3] || timeValue[2]) && timeNumberAfterColon
        }${timeValue[3]}${timeValue[4]}`
      : `${timeValue[1]}${
          (timeValue[3] || timeValue[2]) && timeNumberAfterColon
        }${timeValue[2]}${timeValue[3]}`

    setTime(inputCard.current.value)
    setTimeForDefaultValue(inputCard.current.value)
    console.log(timeValue)
  }

  const handleChangeReturnTime = () => {
    const timeNumberAfterColon = ":"

    const setZeroOrNot = (timeValue1) => {
      if (timeValue1 > 1) {
        return 0
      }
      return timeValue1
    }
    const timeValue = inputReturnTimeRef.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})/)
    inputReturnTimeRef.current.value = timeValue[4]
      ? `${setZeroOrNot(timeValue[1])}${timeValue[2]}${
          (timeValue[3] || timeValue[2]) && timeNumberAfterColon
        }${timeValue[3]}${timeValue[4]}`
      : `${timeValue[1]}${
          (timeValue[3] || timeValue[2]) && timeNumberAfterColon
        }${timeValue[2]}${timeValue[3]}`

    setReturnTimeLocal(inputReturnTimeRef.current.value)
    setReturnTime(inputReturnTimeRef.current.value)
    // console.log(timeValue)
  }

  const MeetAndGreetSwitchBlock = (
    <div className={styles.meetAndGreetRow}>
      <div className={styles.meetAndGreetWrapper}>
        <div className={styles.meetAndGreetContainer}>
          <div className={styles.meetAndGreetIconAndNameContainer}>
            {/* <MeetAndGreetIcon color={fontColor} /> */}
            <span
              className={styles.meetAndGreetIconAndNameTitle}
              style={{ color: fontColor }}
            >
              {"Meet & Greet/Luggage Assist"}
            </span>
          </div>

          <div className={styles.meetAndGreetSwitch}>
            <Switch
              checked={gateMeeting}
              onClick={() => {
                if (gateMeeting == false) {
                  setGateMeetingRedux(true)
                  setIsGateMeeting(true)
                  setIsAirportPickupIncludedLocalState(true)
                  console.log("true")
                } else {
                  setGateMeetingRedux(false)
                  setIsGateMeeting(false)
                  setIsAirportPickupIncludedLocalState(false)
                  console.log("false")
                }
              }}
              numberToIdentify={1}
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.luggageCounter}>
        <Luggage
          luggage={luggage}
          setLuggage={setLuggage}
          isGateMeeting={isGateMeeting}
        />
      </div> */}
    </div>
  )

  const returnMeetAndGreetSwitchBlock = (
    <div className={styles.meetAndGreetRow}>
      <div className={styles.meetAndGreetWrapper}>
        <div className={styles.meetAndGreetContainer}>
          <div className={styles.meetAndGreetIconAndNameContainer}>
            {/* <MeetAndGreetIcon color={fontColor} /> */}
            <span
              className={styles.meetAndGreetIconAndNameTitle}
              style={{ color: fontColor }}
            >
              {"Meet & Greet/Luggage Assist"}
            </span>
          </div>

          <div className={styles.meetAndGreetSwitch}>
            <Switch
              checked={returnGateMeeting}
              onClick={() => {
                if (returnGateMeeting == false) {
                  setReturnGateMeetingRedux(true)
                  setIsReturnGateMeeting(true)
                  setReturnIsAirportPickupIncludedLocalState(true)
                  setIsReturnAirportPickupIncludedRedux(true)
                  console.log("true")
                } else {
                  setReturnGateMeetingRedux(false)
                  setIsReturnGateMeeting(false)
                  setReturnIsAirportPickupIncludedLocalState(false)
                  setIsReturnAirportPickupIncludedRedux(false)
                  console.log("false")
                }
              }}
              numberToIdentify={2}
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.luggageCounter}>
        <Luggage
          luggage={luggage}
          setLuggage={setLuggage}
          isGateMeeting={isGateMeeting}
        />
      </div> */}
    </div>
  )

  function onChange(value) {
    console.log("Captcha value:", value)
    window.localStorage.setItem("captcha", value)
    if (Boolean(localStorage.getItem("captcha")) == true) {
      setTimeout(() => setShowRecaptcha(false), 700)
    }
  }

  // const [loadingAirlines, setLoadingAirlines] = useState(false)

  const [mapCenter, setMapCenter] = useState({
    lat: 33.1507,
    lng: -96.8236,
  })
  const [markers, setMarkers] = useState([])

  const needToRelocateMap = useMediaQuery("(max-width: 867px)")

  return (
    <div
      className={styles.mainWrapper}
      style={{
        background: ThemeProviderAppBackgroundColor,
      }}
    >
      <FormProvider {...methods} style={{ width: "100%" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%", height: "100%" }}
        >
          <div className={styles.adressFormWrapperToSetRowDirection}>
            {!needToRelocateMap && (
              <div
                // item
                className={styles.mapContainer}
              >
                <div
                  className={styles.mapContainerForHideMapsTagsPositioning}
                  style={{
                    border: `1px solid ${borderColorForInnerElements}`,
                    borderRadius: borderRadiusesForInnerElements,
                    background: mapBackground,
                  }}
                >
                  <div
                    className={styles.mapContainerForBorder}
                    style={{ borderRadius: borderRadiusesForInnerElements }}
                  >
                    <Map
                      google={props.google}
                      disableDefaultUI={true}
                      initialCenter={{
                        lat: mapCenter.lat,
                        lng: mapCenter.lng,
                      }}
                      center={{
                        lat: mapCenter.lat,
                        lng: mapCenter.lng,
                      }}
                      style={{
                        borderRadius: `${borderRadiusesForInnerElements}`,
                      }}
                      styles={MapStyles}
                      zoom={12}
                    >
                      {markers.map((marker, id) => (
                        <Marker
                          key={`${id}${marker.lat}`}
                          lat={marker.lat}
                          lng={marker.lng}
                        />
                      ))}
                    </Map>
                    {/* <div
                    style={{
                      width: "100%",
                      height: "30px",
                      background: "black",
                      position: "absolute",
                      bottom: "-20px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  ></div> */}
                  </div>
                </div>
              </div>
            )}

            <div className={styles.inputsColumnWrapper}>
              {/* <div className={styles.mapAndDirectionsWrapper}> */}

              {/* </div> */}
              {needToRelocateMap && (
                <div
                  // item
                  className={styles.mapContainerMobile}
                >
                  <div
                    className={styles.mapContainerForHideMapsTagsPositioning}
                    style={{
                      border: `1px solid ${borderColorForInnerElements}`,
                      borderRadius: borderRadiusesForInnerElements,
                      background: mapBackground,
                    }}
                  >
                    <div
                      className={styles.mapContainerForBorder}
                      style={{ borderRadius: borderRadiusesForInnerElements }}
                    >
                      <Map
                        google={props.google}
                        disableDefaultUI={true}
                        initialCenter={{
                          lat: mapCenter.lat,
                          lng: mapCenter.lng,
                        }}
                        center={{
                          lat: mapCenter.lat,
                          lng: mapCenter.lng,
                        }}
                        style={{
                          borderRadius: `${borderRadiusesForInnerElements}`,
                        }}
                        styles={MapStyles}
                        zoom={12}
                      >
                        {markers.map((marker, id) => (
                          <Marker
                            key={`${id}${marker.lat}`}
                            lat={marker.lat}
                            lng={marker.lng}
                          />
                        ))}
                      </Map>
                      {/* <div
                    style={{
                      width: "100%",
                      height: "30px",
                      background: "black",
                      position: "absolute",
                      bottom: "-20px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  ></div> */}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.underMapOptionsWrapper}>
                <div className={styles.underMapOptionsContainer}>
                  <GoogleMap
                    setDestinations={setDestinations}
                    destinations={destinations}
                    orderAddressDetails={formData.orderAddressDetails}
                    redBorderOnSubmit={redBorderOnSubmit}
                    redBorderOnSubmit2={redBorderOnSubmit2}
                    setMapCenter={setMapCenter}
                    markers={markers}
                    setMarkers={setMarkers}
                  />
                  {(isAirline || formData.isAirportPickupIncluded) &&
                    (formData.bookingType === 3 || bookingType === 3) && (
                      <div
                        className={styles.underMapOptionsContainerForAirlines}
                      >
                        <div className={styles.airlinesItem}>
                          {/* <PlaneIcon color={fontColor} /> */}
                          <Autocomplete
                            disablePortal
                            onInputChange={(event, newValue) => {
                              // console.log(event.target.value)
                              newValue
                                ? extractAirlineId(newValue)
                                : setAirlineId(null)
                            }}
                            // onChange={(event, newValue) => {
                            //   newValue
                            //     ? extractAirlineId(newValue)

                            // }}
                            loading={loadingAirlines ? true : false}
                            loadingText={<p>Loading...</p>}
                            style={{ width: "100%" }}
                            options={airlines.map((airline) => airline.name)}
                            renderInput={(params) => (
                              <div
                                ref={params.InputProps.ref}
                                style={{ width: "100%", display: "flex" }}
                              >
                                <input
                                  type="text"
                                  {...params.inputProps}
                                  placeholder="Airlines"
                                  className={styles.airLinesInput}
                                  style={{
                                    width: "100%",
                                    color: inputsFontColor,
                                    border: `1px solid ${borderColorForInnerElements}`,
                                    background: inputsBackground,
                                    borderRadius:
                                      borderRadiusesForInnerElements,
                                  }}
                                />
                              </div>
                            )}
                          />
                        </div>
                        <div className={styles.flightNumberContainer}>
                          <div className={styles.flightNumberItem}>
                            {/* <Ticket color={fontColor} /> */}
                            <input
                              name="flightNumber"
                              placeholder="Flight number"
                              className={styles.flightNumberInput}
                              autoComplete="off"
                              style={{
                                color: inputsFontColor,
                                border: `1px solid ${borderColorForInnerElements}`,
                                borderRadius: borderRadiusesForInnerElements,
                                background: inputsBackground,
                              }}
                              defaultValue={null}
                              value={flightNumber}
                              onChange={(e) => setFlightNumber(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  <div className={styles.dateTimeBlock}>
                    <div className={styles.dateTimeBlockContainer}>
                      <div className={styles.datePicker}>
                        {/* <DateIcon color={fontColor} /> */}
                        <div
                          onClick={() => setShowDatePickerCalendarModal(true)}
                          className={
                            // redBorderOnSubmitForDate
                            //   ? styles.datePickerOpenButtonWithRedBorder
                            //   :

                            styles.datePickerOpenButton
                          }
                          placeholder="Pick up Date"
                          // value={
                          //   formData.dateForDefaultValue && !resetInputs
                          //     ? formData.dateForDefaultValue
                          //     : date?.toLocaleDateString("en-US")
                          // }
                          style={{
                            color: inputsFontColor,
                            border: !redBorderOnSubmitForDate
                              ? `1px solid ${borderColorForInnerElements}`
                              : `1px solid red`,
                            borderRadius: borderRadiusesForInnerElements,
                            background: inputsBackground,
                          }}
                        >
                          {formData.dateForDefaultValue && !resetInputs
                            ? formData.dateForDefaultValue
                            : date?.toLocaleDateString("en-US")}

                          {!formData.dateForDefaultValue ? (
                            <span style={{ color: "grey", paddingTop: "10px" }}>
                              Pick up Date
                            </span>
                          ) : null}
                        </div>

                        <Modal
                          onClose={() => setShowDatePickerCalendarModal(false)}
                          show={showDatePickerCalendarModal}
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div>
                              <CalendarPicker
                                minDate={new Date()}
                                date={date}
                                onChange={(newDate) => {
                                  console.log(newDate)
                                  if (newDate instanceof Date) {
                                    setShowDatePickerCalendarModal(false)
                                  }
                                  setDateForDefaultValue(
                                    newDate.toLocaleDateString("en-US")
                                  )
                                  setDate(newDate)
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                          </LocalizationProvider>
                        </Modal>
                      </div>
                      <div className={styles.timePicker}>
                        <div className={styles.timePickerContainer}>
                          {/* <ClockIcon color={fontColor} /> */}
                          <input
                            name="orderStartTime"
                            placeholder="hh:mm"
                            autoComplete="off"
                            className={styles.timePickerInput}
                            setTime={setTime}
                            ref={inputCard}
                            onClick={(event) => {
                              const { value } = event.target
                              const position = value.length
                              event.target.setSelectionRange(position, position)
                            }}
                            onChange={handleChange}
                            style={{
                              color: inputsFontColor,
                              border:
                                redBorderOnSubmitForTime ||
                                redBorderOnSubmitForTime2 ||
                                redBorderOnSubmitForTime3 ||
                                redBorderOnSubmitForTime4
                                  ? `1px solid red`
                                  : `1px solid ${borderColorForInnerElements}`,
                              borderRadius: borderRadiusesForInnerElements,
                              background: inputsBackground,
                              textAlign: "right",
                              paddingRight: "78px",
                            }}
                            value={
                              !resetInputs ? formData.timeForDefaultValue : null
                            }
                          />
                          <div className={styles.toggleButtonsContainer}>
                            <div
                              className={styles.toggleButtonAM}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleChangeAMPM(e)
                              }}
                              style={{
                                color:
                                  AMPM == "AM"
                                    ? AMPMHoverFontColor
                                    : inputsFontColor,
                                background:
                                  AMPM == "AM"
                                    ? AMPMHoverBackgroundColor
                                    : "transparent",
                                opacity: AMPM == "AM" ? "1" : "0.5",
                                borderRadius: `calc(${borderRadiusesForInnerElements} - 2px)`,
                              }}
                            >
                              AM
                            </div>
                            <div
                              className={styles.toggleButtonPM}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleChangeAMPM(e)
                              }}
                              style={{
                                color:
                                  AMPM == "PM"
                                    ? AMPMHoverFontColor
                                    : inputsFontColor,
                                background:
                                  AMPM == "PM"
                                    ? AMPMHoverBackgroundColor
                                    : "transparent",
                                opacity: AMPM == "PM" ? "1" : "0.5",
                                borderRadius: `calc(${borderRadiusesForInnerElements} - 2px)`,
                              }}
                            >
                              PM
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(isAirline || formData.isAirportPickupIncluded) &&
                    (formData.bookingType === 3 || bookingType === 3) &&
                    MeetAndGreetSwitchBlock}
                  {destinations[1]?.rideCheckPoint.match(
                    /(^|\W)Airport($|\W)/
                  ) && MeetAndGreetSwitchBlock}
                  <div className={styles.roundTripRow}>
                    <div
                      className={styles.roundTripWrapper}
                      style={{
                        opacity: destinations[1].placeId ? "1" : "0.5",
                        pointerEvents: destinations[1].placeId
                          ? "auto"
                          : "none",
                      }}
                    >
                      <div className={styles.roundTripContainer}>
                        <div className={styles.roundTripIconAndNameContainer}>
                          {/* <SafetySeatIcon color={fontColor} /> */}
                          <span
                            className={styles.roundTripIconAndNameTitle}
                            style={{ color: `${fontColor}  ` }}
                          >
                            Round Trip
                          </span>
                        </div>
                        <Switch
                          checked={formData.isRoundTrip}
                          onClick={() => {
                            console.log(returnDestinations)
                            setReturnDestinations([
                              destinations[destinations.length - 1],
                              destinations[0],
                            ])
                            setRoundTripSwitch(!roundTripSwitch)
                            setIsRoundTrip(!formData.isRoundTrip)
                          }}
                          numberToIdentify={5}
                        />
                      </div>
                    </div>
                  </div>
                  {formData.isRoundTrip && (
                    <>
                      <div
                        className={
                          styles.mapInputsContainerForHideMapsTagsPositioning
                        }
                        style={{ background: ThemeProviderAppBackgroundColor }}
                      >
                        <div className={styles.mapInputsContainer}>
                          <TransitionGroup>
                            {returnDestinations.map((destination, id) => {
                              // console.log(destinations)

                              return (
                                <CSSTransition
                                  key={id}
                                  classNames={{
                                    enter: "enter",
                                    enterActive: "enterActive",
                                    exit: "exit",
                                    exitActive: "exitActive",
                                  }}
                                  timeout={300}
                                >
                                  <PlacesAutocomplete
                                    value={
                                      // formData.returnOrderAddressDetails[id]
                                      //   ?.rideCheckPoint && !resetInputs
                                      //   ? formData.returnOrderAddressDetails[id]
                                      //       ?.rideCheckPoint
                                      //   : null
                                      returnDestinations[id].rideCheckPoint
                                    }
                                    onChange={(value) => {
                                      handleChangeAddress(value, id)
                                    }}
                                    onSelect={(value) => {
                                      handleSelect(value, id)
                                    }}
                                    key={`${destination.id}${id}`}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => {
                                      // copiedSuggestions = [...suggestions].filter(
                                      //   (item) => {
                                      //     return !airportCenter4.includes(
                                      //       item.description
                                      //     )
                                      //   }
                                      // )
                                      return (
                                        <>
                                          <div
                                            className={
                                              styles.destinationsInputContainer
                                            }
                                          >
                                            {id > 0 &&
                                              id <
                                                returnDestinations.length -
                                                  1 && (
                                                <span
                                                  className={
                                                    styles.numberOfDestinationPoint
                                                  }
                                                  style={{
                                                    color: inputsFontColor,
                                                    border: `2px solid ${inputsFontColor}`,
                                                  }}
                                                >
                                                  {id}
                                                </span>
                                              )}
                                            {/* {getDestinationsIcons(
                                              id,
                                              returnDestinations
                                            )} */}
                                            <input
                                              value={
                                                formData.orderAddressDetails[id]
                                                  ?.rideCheckPoint &&
                                                !resetInputs
                                                  ? formData
                                                      .orderAddressDetails[id]
                                                      ?.rideCheckPoint
                                                  : null
                                              }
                                              name="rideCheckPoint"
                                              defaultValue={
                                                returnDestinations[id]
                                                  ?.rideCheckPoint
                                              }
                                              // inputRef={ref}
                                              style={{
                                                color: inputsFontColor,
                                                border: `1px solid ${borderColorForInnerElements}`,
                                                background: inputsBackground,
                                                borderRadius:
                                                  borderRadiusesForInnerElements,
                                              }}
                                              inputsFontColor={inputsFontColor}
                                              placeholder={
                                                id === 0 ? "From" : "To"
                                              }
                                              className={setDestinationsIcons(
                                                id,
                                                returnDestinations
                                              )}
                                              {...getInputProps()}
                                            />
                                            {id ===
                                              returnDestinations.length - 1 && (
                                              <span
                                                onClick={addEndPoint}
                                                className={
                                                  styles.addLocationIcon
                                                }
                                              >
                                                <AddLocIcon
                                                  color={inputsFontColor}
                                                />
                                              </span>
                                            )}
                                            {id > 0 &&
                                              id <
                                                returnDestinations.length -
                                                  1 && (
                                                <span
                                                  onClick={() =>
                                                    removeEndPoint(id)
                                                  }
                                                  className={
                                                    styles.deleteLocationIcon
                                                  }
                                                >
                                                  <DeleteLocIcon
                                                    color={inputsFontColor}
                                                  />
                                                </span>
                                              )}

                                            <div className={styles.dropDown}>
                                              {loading && (
                                                <div
                                                  className={
                                                    styles.dropDownLoadingText
                                                  }
                                                >
                                                  Loading...
                                                </div>
                                              )}
                                              {suggestions.map(
                                                (suggestion, id) => {
                                                  return (
                                                    <div
                                                      key={`${id}${suggestion.description}`}
                                                      {...getSuggestionItemProps(
                                                        suggestion
                                                      )}
                                                      className={
                                                        styles.itemInsideDropDown
                                                      }
                                                    >
                                                      {suggestion.description}
                                                    </div>
                                                  )
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }}
                                  </PlacesAutocomplete>
                                </CSSTransition>
                              )
                            })}
                          </TransitionGroup>
                        </div>
                      </div>
                      {(isReturnAirline ||
                        isAirline ||
                        formData.isAirportPickupIncluded) &&
                        (formData.bookingType === 3 || bookingType === 3) && (
                          <div
                            className={
                              styles.underMapOptionsContainerForAirlines
                            }
                          >
                            <div className={styles.airlinesItem}>
                              {/* <PlaneIcon color={fontColor} /> */}
                              <Autocomplete
                                disablePortal
                                onChange={(event, newValue) => {
                                  newValue
                                    ? extractAirlineId(newValue)
                                    : setReturnAirlineId(null)
                                }}
                                style={{ width: "100%" }}
                                options={airlines.map(
                                  (airline) => airline.name
                                )}
                                renderInput={(params) => (
                                  <div
                                    ref={params.InputProps.ref}
                                    style={{ width: "100%", display: "flex" }}
                                  >
                                    <input
                                      type="text"
                                      {...params.inputProps}
                                      placeholder="Airlines"
                                      className={styles.airLinesInput}
                                      style={{
                                        width: "100%",
                                        color: inputsFontColor,
                                        border: `1px solid ${borderColorForInnerElements}`,
                                        background: inputsBackground,
                                        borderRadius:
                                          borderRadiusesForInnerElements,
                                      }}
                                    />
                                  </div>
                                )}
                              />
                            </div>
                            <div className={styles.flightNumberContainer}>
                              <div className={styles.flightNumberItem}>
                                {/* <Ticket color={fontColor} /> */}
                                <input
                                  name="returnFlightNumber"
                                  placeholder="Flight number"
                                  className={styles.flightNumberInput}
                                  autoComplete="off"
                                  style={{
                                    color: inputsFontColor,
                                    border: `1px solid ${borderColorForInnerElements}`,
                                    borderRadius:
                                      borderRadiusesForInnerElements,
                                    background: inputsBackground,
                                  }}
                                  defaultValue={null}
                                  value={returnFlightNumber}
                                  onChange={(e) =>
                                    setReturnFlightNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      <div className={styles.dateTimeBlock}>
                        <div className={styles.dateTimeBlockContainer}>
                          <div className={styles.datePicker}>
                            {/* <DateIcon color={inputsFontColor} /> */}
                            <div
                              onClick={() =>
                                setShowReturnDatePickerCalendarModal(true)
                              }
                              className={
                                // redBorderOnSubmitForDate
                                //   ? styles.datePickerOpenButtonWithRedBorder
                                //   :
                                styles.datePickerOpenButton
                              }
                              // placeholder="Pick up Date"
                              // value={
                              //   formData.dateForDefaultValue && !resetInputs
                              //     ? formData.dateForDefaultValue
                              //     : date?.toLocaleDateString("en-US")
                              // }
                              style={{
                                color: inputsFontColor,
                                border: !redBorderOnSubmitForReturnDate
                                  ? `1px solid ${borderColorForInnerElements}   `
                                  : `1px solid red   `,
                                borderRadius: `${borderRadiusesForInnerElements}   `,
                                background: `${inputsBackground}   `,
                              }}
                            >
                              <span
                                style={{
                                  color: `${inputsFontColor}   `,
                                  fontSize: "13.5px   ",
                                }}
                              >
                                {formData.returnDateValue || returnDateLocal
                                  ? formData.returnDateValue || returnDateLocal
                                  : `Return Date`}
                              </span>
                            </div>

                            <Modal
                              onClose={() =>
                                setShowReturnDatePickerCalendarModal(false)
                              }
                              show={showReturnDatePickerCalendarModal}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <div>
                                  <CalendarPicker
                                    minDate={new Date()}
                                    date={returnDateLocal}
                                    onChange={(newDate) => {
                                      // console.log(newDate)
                                      if (newDate instanceof Date) {
                                        setShowReturnDatePickerCalendarModal(
                                          false
                                        )
                                      }
                                      setReturnDate(
                                        newDate.toLocaleDateString("en-US")
                                      )
                                      setReturnDateLocal(newDate)
                                    }}
                                  />
                                </div>
                              </LocalizationProvider>
                            </Modal>
                          </div>
                          <div className={styles.timePicker}>
                            <div className={styles.timePickerContainer}>
                              {/* <ClockIcon color={inputsFontColor} /> */}
                              <input
                                name="orderStartTime"
                                placeholder="hh:mm"
                                autoComplete="off"
                                className={styles.timePickerInput}
                                setTime={setTime}
                                ref={inputReturnTimeRef}
                                onClick={(event) => {
                                  const { value } = event.target
                                  const position = value.length
                                  event.target.setSelectionRange(
                                    position,
                                    position
                                  )
                                }}
                                onChange={handleChangeReturnTime}
                                style={{
                                  color: `${inputsFontColor}`,
                                  border:
                                    redBorderOnSubmitForReturnTime ||
                                    redBorderOnSubmitForReturnTime2 ||
                                    redBorderOnSubmitForReturnTime3 ||
                                    redBorderOnSubmitForReturnTime4
                                      ? `1px solid red`
                                      : `1px solid ${borderColorForInnerElements}`,
                                  background: `${inputsBackground}`,
                                  textAlign: "right",
                                  paddingRight: "78px",
                                  borderRadius: `${borderRadiusesForInnerElements}`,
                                }}
                                inputsFontColor={inputsFontColor}
                                value={
                                  !resetInputs ? formData.returnTimeValue : null
                                }
                              />
                              <div className={styles.toggleButtonsContainer}>
                                <div
                                  className={styles.toggleButtonAM}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleChangeReturnAMPM(e)
                                  }}
                                  style={{
                                    color:
                                      returnAMPM == "AM"
                                        ? AMPMHoverFontColor
                                        : inputsFontColor,
                                    background:
                                      returnAMPM == "AM"
                                        ? AMPMHoverBackgroundColor
                                        : "transparent",
                                    opacity: returnAMPM == "AM" ? "1" : "0.5",
                                    borderRadius: `calc(${borderRadiusesForInnerElements} - 2px)`,
                                  }}
                                >
                                  AM
                                </div>
                                <div
                                  className={styles.toggleButtonPM}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleChangeReturnAMPM(e)
                                  }}
                                  style={{
                                    color:
                                      returnAMPM == "PM"
                                        ? AMPMHoverFontColor
                                        : inputsFontColor,
                                    background:
                                      returnAMPM == "PM"
                                        ? AMPMHoverBackgroundColor
                                        : "transparent",
                                    opacity: returnAMPM == "PM" ? "1" : "0.5",
                                    borderRadius: `calc(${borderRadiusesForInnerElements} - 2px)`,
                                  }}
                                >
                                  PM
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(isAirline ||
                        isReturnAirline ||
                        formData.isAirportPickupIncluded) &&
                        (formData.bookingType === 3 || bookingType === 3) && (
                          <>{returnMeetAndGreetSwitchBlock}</>
                        )}
                      {destinations[1]?.rideCheckPoint.match(
                        /(^|\W)Airport($|\W)/
                      ) && (
                        <>
                          {returnMeetAndGreetSwitchBlock}
                          {/* <div className={styles.luggageCounter}>
                            <Luggage
                              luggage={luggage}
                              setLuggage={setLuggage}
                              isGateMeeting={isGateMeeting}
                              isReturnGateMeeting={isReturnGateMeeting}
                            />
                          </div> */}
                        </>
                      )}
                    </>
                  )}

                  {(isAirline ||
                    isReturnAirline ||
                    formData.isAirportPickupIncluded) &&
                    (formData.bookingType === 3 || bookingType === 3) && (
                      <div className={styles.luggageCounter}>
                        <Luggage
                          luggage={luggage}
                          setLuggage={setLuggage}
                          isGateMeeting={isGateMeeting}
                          isReturnGateMeeting={isReturnGateMeeting}
                        />
                      </div>
                    )}
                  {destinations[1]?.rideCheckPoint.match(
                    /(^|\W)Airport($|\W)/
                  ) && (
                    <div className={styles.luggageCounter}>
                      <Luggage
                        luggage={luggage}
                        setLuggage={setLuggage}
                        isGateMeeting={isGateMeeting}
                        isReturnGateMeeting={isReturnGateMeeting}
                      />
                    </div>
                  )}

                  <div
                    style={{ width: "100%" }}
                    className={styles.passengersQuantityBlock}
                  >
                    <div className={styles.passengersQuantityBlockContainer}>
                      <PassengerQuantity
                        passengersqState={formData.passengersQuantity}
                        setPassengers={setPassengers}
                        passengers={passengers}
                        setPassengersQuantityForBackStep={
                          setPassengersQuantityForBackStep
                        }
                        passengersQuantityForBackStep={
                          formData.passengersQuantityForBackStep
                        }
                        redBorderOnSubmitForPassengers={
                          redBorderOnSubmitForPassengers
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.hourlyRow}>
                    <div className={styles.hourlyWrapper}>
                      <div className={styles.hourlyRowContainer}>
                        <div className={styles.hourlyIconAndNameContainer}>
                          {/* <HourlyIcon color={fontColor} /> */}
                          <span
                            className={styles.hourlyIconAndNameTitle}
                            style={{ color: fontColor }}
                          >
                            Hourly
                          </span>
                        </div>
                        <Switch
                          checked={hourlyAndSeatsRedux}
                          onClick={() => {
                            if (!hourlyAndSeatsRedux) {
                              setHourlyRedux(true)
                            } else {
                              setHourlyRedux(false)
                            }
                            setHourly(!hourly)
                          }}
                          numberToIdentify={3}
                        />
                      </div>
                    </div>
                    <div className={styles.hourlyCounter}>
                      {/* {hourlyAndSeatsRedux === true && (
                      
                    )} */}
                      <Hours
                        hoursState={formData.hours}
                        hourly={hourly}
                        hourlyAndSeatsRedux={hourlyAndSeatsRedux}
                        hoursAddressForm={hoursAddressForm}
                        setHoursAddressForm={setHoursAddressForm}
                        redBorderOnSubmitForHours={redBorderOnSubmitForHours}
                        setHoursRedux={setHoursRedux}
                        hoursCount={hoursCount}
                      />
                    </div>
                  </div>
                  <div className={styles.safetySeatRow}>
                    {(isBoosterSeatExistOnBackend ||
                      isSafetySeatExistOnBackend) && (
                      <div className={styles.safetySeatWrapper}>
                        <div className={styles.safetySeatContainer}>
                          <div
                            className={styles.safetySeatIconAndNameContainer}
                          >
                            {/* <SafetySeatIcon color={fontColor} /> */}
                            <span
                              className={styles.safetySeatIconAndNameTitle}
                              style={{ color: fontColor }}
                            >
                              Safety Seat
                            </span>
                          </div>
                          <Switch
                            checked={formData.showCarsWithSafetySeat}
                            onClick={() => {
                              setSafetySeat(!safetySeat)
                              setShowCarsWithSafetySeat(!safetySeat)
                            }}
                            numberToIdentify={2}
                          />
                        </div>
                      </div>
                    )}
                    <div className={styles.SafetySeatCounterWrapper}>
                      {/* {formData.showCarsWithSafetySeat === true && (
                      
                    )} */}
                      <div className={styles.SafetySeatCounterContainer}>
                        <SafetySeat
                          safetySeat={safetySeat}
                          setBoosterSeat={setBoosterSeat}
                          setChildSafetySeat={setChildSafetySeat}
                          boosterSeat={boosterSeat}
                          childSafetySeat={childSafetySeat}
                          isBoosterSeatExistOnBackend={
                            isBoosterSeatExistOnBackend
                          }
                          isSafetySeatExistOnBackend={
                            isSafetySeatExistOnBackend
                          }
                          showCarsWithSafetySeat={
                            formData.showCarsWithSafetySeat
                          }
                          setSafetySeatCount={setSafetySeatCount}
                          setBoosterSeatCount={setBoosterSeatCount}
                          safetySeatCountRedux={formData.safetySeatCount}
                          boosterSeatCountRedux={formData.boosterSeatCount}
                          // showCarsWithSafetySeat={
                          //   formData.showCarsWithSafetySeat
                          // }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.preferencesWrapper}>
                    {/* <div className={styles.preferencesTitleContainer}>
                      <span
                        className={styles.preferencesTitle}
                        style={{ color: fontColor }}
                      >
                        Preferences
                      </span>
                    </div>
                    <div
                      className={styles.preferencesCarsContainer}
                      style={{
                        borderRadius: `${borderRadiusesForInnerElements}`,
                        border: redBorderOnSubmitForCarType
                          ? "1px solid red"
                          : "1px solid transprent",
                      }}
                    >
                      <Carousel
                        renderArrow={myArrow}
                        itemsToShow={isMobile ? 3 : 4}
                        pagination={false}
                        transitionMs={300}
                      >
                        {carTypes.map((car, indexOfEachCar) => (
                          <CarItemContainer
                            hoverColor={hoverColor}
                            borderRadiusesForInnerElements={
                              borderRadiusesForInnerElements
                            }
                            carsTypeColor={carsTypeColor}
                            carSelected={car.id === carSelectionID}
                            fontColor={fontColor}
                            innerTextOnHover={innerTextOnHover}
                            onClick={() => handleClick(car.id)}
                            name="carsValidation"
                          >
                            <div className={styles.carItemTitleContainer}>
                              <span className={styles.carItemTitle}>
                                {car.name}
                              </span>
                            </div>
                            <div item className={styles.carImageContainer}>
                              <img
                                alt="carImage"
                                src={car.imageUrl}
                                className={
                                  indexOfEachCar == 2
                                    ? styles.carImageStylesForBiggerTypeOfImage
                                    : styles.carImage
                                }
                                item
                                // className={styles.carImage}
                              />
                              {console.log(indexOfEachCar == 2)}
                            </div>
                          </CarItemContainer>
                        ))}
                      </Carousel>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal onClose={() => setShowRecaptcha(false)} show={showRecaptcha}>
            <ReCAPTCHA
              sitekey="6LeuP3weAAAAAHoe3aaP27xmYorD1s1vXK7XdlPk"
              onChange={onChange}
            />
          </Modal>
          <div
            className={styles.buttonGroupBlockContainer}
            // onClick={(event) => {
            //   // event.stopPropagation()
            //   console.log(localStorage.getItem("captcha") !== true)
            //   Boolean(localStorage.getItem("captcha")) !== true &&
            //     setShowRecaptcha(true)
            // }}
          >
            <button
              type="submit"
              className={styles.buttonNextSelf}
              style={{
                background: backAndNextButtonsColor,
                color: backAndNextButtonsFontColor,
                border: `1px solid ${backAndNextButtonsBorderColor}`,
                borderRadius: borderRadiusesForInnerElements,
              }}
            >
              Next
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCmKhi_5V_pulQtm6DFJ8teDZpR9I5hJoM",
//   libraries: ["places", "drawing", "geometry"],
// })(connect(mapStateToProps, {})(GoogleMap))

const AdressFormWithGoogleMap = GoogleApiWrapper({
  apiKey: "AIzaSyCmKhi_5V_pulQtm6DFJ8teDZpR9I5hJoM",
  libraries: ["places", "drawing", "geometry"],
})(AdressFormwithoutReactMemo)

const AdressFormUIComponent = React.memo(AdressFormWithGoogleMap)

export default AdressFormUIComponent

const CarItemContainer = styled.div`
  width: 60px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:${(props) => props.borderRadiusesForInnerElements};
  padding: 3px;
  outline: none;
  background: ${(props) => {
    if (!props.carSelected) {
      return props.carsTypeColor
    } else {
      return props.hoverColor
    }
  }}} ;
  span{
    color:
    
    ${(props) => {
      if (props.carSelected) {
        return props.innerTextOnHover
      } else {
        return props.fontColor
      }
    }}};
  }

  transition: 0.1s;
  &:hover {
    background: ${(props) => props.hoverColor};
    cursor: pointer;
    transition: 0.1s;

    span{
    color:${(props) => props.innerTextOnHover} ;
  }
  }
`
