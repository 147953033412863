import React, { useState } from "react"
import { connect } from "react-redux"
import {
  getNewTotalWithGratuity,
  setNoteRedux,
  setOrderSum,
  setTipsPercentage,
  setIsCustomTips,
} from "../../../../Redux/form-reducer"
import PreviewUIComponent from "./PreviewUIComponent"

const PreviewContainerComponent = ({
  carId,
  cars,
  formData,
  next,
  back,
  setNoteRedux,
  setOrderSum,
  hourlyAndSeatsRedux,
  gateMeeting,
  getNewTotalWithGratuity,
  setTipsPercentage,
  setIsCustomTips,
}) => {
  const selectedCar = cars.find((car) => car.id === carId)

  const [note, setNote] = React.useState("")
  const [distance, setDistance] = React.useState(0)

  const sendNote = (note) => {
    setNoteRedux(note)
  }

  const handleChange = (event) => {
    setNote(event.target.value)
  }
  const [carCard, setCarCard] = React.useState(0)
  const [carModal, setCarModal] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const [show, setShow] = React.useState(false)

  const handleClickOpen = (id) => {
    setCarModal(true)
    setShow(true)
  }

  const handleClickClose = () => {
    setCarModal(null)
    setShow(false)
  }

  const handleClick = (id) => {
    setCarCard(id)
  }

  let result = null
  if (carModal) {
    result = cars.find((cars) => carModal === cars.id)
  }

  React.useEffect(() => {
    setNote(formData.orderNotes)
  }, [formData.orderNotes])

  const round = (n, dp) => {
    const h = +"1".padEnd(dp + 1, "0") // 10 or 100 or 1000 or etc
    return Math.round(n * h) / h
  }

  const showCarAmount = () => {
    if (selectedCar.boosterSeatPrice || selectedCar.safetySeatPrice) {
      return `$${round(
        selectedCar.price -
          selectedCar.boosterSeatPrice -
          selectedCar.safetySeatPrice,
        2
      )}`
    } else {
      return `$${round(selectedCar.price, 2)} `
    }
  }

  const [showGratuityDropdown, setShowGratuityDropdown] = React.useState(false)

  const [gratuityDropdownValue, setGratuityDropdownValue] =
    React.useState("18%")

  const setGratuity = (amount, type) => {
    getNewTotalWithGratuity({
      OrderSum: selectedCar.price,
      Tips: amount
        ? {
            AmountType: type == 1 ? 1 : 2,
            Amount: amount,
          }
        : null,
    })
  }

  const [redBorderToCustomTipsOkButton, setRedBorderToCustomTipsOkButton] =
    useState(false)

  // const [customTipsButtonsShow, setCustomTipsButtonsShow] = useState(null)

  const [openCustomTipsModal, setOpenCustomTipsModal] = useState(false)

  const [customTipsInputValue, setCustomTipsInputValue] = useState("")

  const keyPressHanlder = (event) => {
    const { key } = event
    setCustomTipsInputValue((prevValue) =>
      key !== "Backspace"
        ? !Number.isNaN(parseInt(key)) || key === "," || key === "."
          ? prevValue + key
          : prevValue
        : prevValue.substring(0, prevValue.length - 1)
    )
  }

  const currentcyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })

  return (
    <PreviewUIComponent
      carId={carId}
      cars={cars}
      formData={formData}
      next={next}
      back={back}
      setNoteRedux={setNoteRedux}
      setOrderSum={setOrderSum}
      hourlyAndSeatsRedux={hourlyAndSeatsRedux}
      gateMeeting={gateMeeting}
      selectedCar={selectedCar}
      note={note}
      setNote={setNote}
      distance={distance}
      setDistance={setDistance}
      sendNote={sendNote}
      handleChange={handleChange}
      carCard={carCard}
      setCarCard={setCarCard}
      carModal={carModal}
      setCarModal={setCarModal}
      open={open}
      setOpen={setOpen}
      handleClickOpen={handleClickOpen}
      handleClickClose={handleClickClose}
      handleClick={handleClick}
      round={round}
      showCarAmount={showCarAmount}
      show={show}
      setShow={setShow}
      showGratuityDropdown={showGratuityDropdown}
      setShowGratuityDropdown={setShowGratuityDropdown}
      gratuityDropdownValue={gratuityDropdownValue}
      setGratuityDropdownValue={setGratuityDropdownValue}
      setGratuity={setGratuity}
      // isCustomTips={isCustomTips}
      // setIsCustomTips={setIsCustomTips}
      setTipsPercentage={setTipsPercentage}
      setIsCustomTips={setIsCustomTips}
      redBorderToCustomTipsOkButton={redBorderToCustomTipsOkButton}
      setRedBorderToCustomTipsOkButton={setRedBorderToCustomTipsOkButton}
      openCustomTipsModal={openCustomTipsModal}
      setOpenCustomTipsModal={setOpenCustomTipsModal}
      customTipsInputValue={customTipsInputValue}
      setCustomTipsInputValue={setCustomTipsInputValue}
      keyPressHanlder={keyPressHanlder}
      currentcyFormat={currentcyFormat}
      // orderNotesRedux={formData.orderNotes}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars.cars,
    formData: state.formData,
    carId: state.formData.carInfo.id,
    hourlyAndSeatsRedux: state.hourlyAndSeatsRedux.hourlyRedux,
    gateMeeting: state.gateMeeting.isGateMeeting,
  }
}

export default connect(mapStateToProps, {
  setNoteRedux,
  setOrderSum,
  getNewTotalWithGratuity,
  setTipsPercentage,
  setIsCustomTips,
})(PreviewContainerComponent)
