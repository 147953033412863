import { useMediaQuery } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { AspectRatio } from "react-aspect-ratio"
import Carousel, { consts } from "react-material-ui-carousel"
import { BackArrowIcon, ForwardArrowIcon } from "../../../../assets/icons"
import Directions from "../../../GoogleMap/Directions/Directions"
import styles from "./Preview.module.scss"
import { Modal } from "../../../Helpers/Modal/Modal"
import ThemeContext from "../../../../context"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../FleetForm/FleetForm.css"
import { CloseCustomTipsIcon } from "../../../../assets/icons"
import { ModalForCustomTips } from "../../../Helpers/ModalForCustomTips/ModalForCustomTips"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "0", zIndex: "30" }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "0", zIndex: "30" }}
      onClick={onClick}
    />
  )
}

const PreviewReusableUIComponent = ({
  carId,
  cars,
  formData,
  next,
  back,
  setNoteRedux,
  setOrderSum,
  hourlyAndSeatsRedux,
  gateMeeting,
  selectedCar,
  note,
  setNote,
  distance,
  setDistance,
  sendNote,
  handleChange,
  carCard,
  setCarCard,
  carModal,
  setCarModal,
  open,
  setOpen,
  handleClickOpen,
  handleClickClose,
  handleClick,
  round,
  showCarAmount,
  show,
  setShow,
  orderNotesRedux,
  showGratuityDropdown,
  setShowGratuityDropdown,
  gratuityDropdownValue,
  setGratuityDropdownValue,
  setGratuity,
  // isCustomTips,
  setIsCustomTips,
  setTipsPercentage,
  redBorderToCustomTipsOkButton,
  setRedBorderToCustomTipsOkButton,
  openCustomTipsModal,
  setOpenCustomTipsModal,
  customTipsInputValue,
  setCustomTipsInputValue,
  keyPressHanlder,
  currentcyFormat,
}) => {
  const isMobile = useMediaQuery("(max-width:500px)")

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          height: "6px",
          backgroundColor: "transparent",
          // borderRadius: "10px",
          padding: "0",
          bottom: "10px",
        }}
      >
        <ul style={{ margin: "0px", paddingLeft: "0px", textAlign: "center" }}>
          {dots}
        </ul>
      </div>
    ),
    // customPaging: function (i) {
    //   return (
    //     <div
    //     // style={{
    //     //   width: "3px",
    //     //   height: "3px",
    //     //   background: "red",

    //     //   // bottom: "6px",
    //     // }}
    //     ></div>
    //   )
    // },
    dotsClass: "button__bar",
  }

  const {
    ThemeProviderAppBackgroundColor,
    fontColor,
    borderRadiuses,
    carsTypeColor,
    hoverColor,
    iconsColor,
    backAndNextButtonsColor,
    innerTextOnHover,
    inputsFontColor,
    borderRadiusesForInnerElements,
    borderRadiusesForWholeApp,
    borderColorForInnerElements,
    borderColorForOuterApp,
    fleetCarsBackgroundColor,
    fleetCarsBorderColor,
    dropDownButtonBackroundColor,
    dropDownButtonFontColor,
    dropDownItemHoverAndSelectBackgroundColor,
    dropDownItemHoverAndSelectFontColor,
    customTipsOkButtonBackground,
    customTipsOkButtonColor,
    customTipsCancelButtonBackground,
    customTipsCancelButtonColor,
    customTipsInputBackground,
    customTipsInputColor,
  } = useContext(ThemeContext)

  useEffect(() => {
    const input = document.getElementById("customTipsInput")
    input.focus()
  }, [openCustomTipsModal])

  return (
    <>
      <div
        className={styles.reservationDetailsContainer}
        style={{
          zIndex: "15",
          backgroundColor: fleetCarsBackgroundColor,
          border: `1px solid ${fleetCarsBorderColor}`,
          borderRadius: borderRadiusesForInnerElements,
          //   paddingTop: "20px",
        }}
      >
        <div
          className={styles.carContainer}
          // style={{
          //   background: `${backAndNextButtonsColor}`,
          //   border: `1px solid ${borderColorForInnerElements}`,
          // }}
        >
          <div className={styles.carImageBlock}>
            <Slider {...settings}>
              {selectedCar.imageUrls.length !== 0 ? (
                selectedCar.imageUrls.map((url) => (
                  <span
                    key={url.id}
                    // variant="outlined"
                    // color="primary"
                  >
                    <div
                      // style={{
                      //   position: "absolute",
                      //   width: "75px",
                      //   height: "20px",
                      //   backgroundColor: "#AC8159",
                      //   color: "black",
                      //   fontSize: "13px",
                      //   paddingLeft: "12px",
                      //   borderTopLeftRadius: "9px",
                      //   paddingTop: "2px",
                      // }}
                      className={styles.orSimiliar}
                      style={{
                        background: fleetCarsBackgroundColor,
                        color: fontColor,
                      }}
                    >
                      or similar
                    </div>
                    {/* <AspectRatio
                      // ratio="560/315"
                      style={{
                        display: "block",
                        width: !isMobile ? "100%" : "100%",
                        height: !isMobile ? "112px" : "116px",

                        cursor: "zoom-in",
                      }}
                    >
                      <img
                        src={url.path}
                        // style={{
                        //   width: !isMobile ? "100%" : "100%",
                        //   height: !isMobile ? "118px" : "116px",
                        //   borderRadius: "9px",
                        //   cursor: "zoom-in",
                        // }}
                        alt="car"
                        onClick={() => handleClickOpen()}
                        className={styles.carImageSelf}
                      />
                    </AspectRatio> */}
                    <img
                      src={url.path}
                      // style={{
                      //   width: !isMobile ? "100%" : "100%",
                      //   height: !isMobile ? "118px" : "116px",
                      //   borderRadius: "9px",
                      //   cursor: "zoom-in",
                      // }}
                      alt="car"
                      onClick={() => handleClickOpen()}
                      className={styles.carImageSelf}
                      style={{
                        // display: "block",

                        cursor: "zoom-in",
                      }}
                    />
                  </span>
                ))
              ) : (
                <>
                  <span
                    className={styles.orSimiliar}
                    style={{
                      background: backAndNextButtonsColor,
                      color: fontColor,
                    }}
                  >
                    or similar
                  </span>
                  <AspectRatio
                    // ratio="560/315"
                    style={{
                      display: "block",

                      width: !isMobile ? "100%" : "100%",
                      height: !isMobile ? "220px" : "116px",

                      cursor: "zoom-in",
                    }}
                  >
                    <img
                      src={"https://fl-1.cdn.flockler.com/embed/not-found.png"}
                      // style={{
                      //   width: !isMobile ? "100%" : "100%",
                      //   height: !isMobile ? "118px" : "116px",
                      //   borderRadius: "9px",
                      // }}
                      alt="car"
                      className={styles.carImageSelf}
                    />
                  </AspectRatio>
                </>
              )}
            </Slider>
            <Modal onClose={() => handleClickClose()} show={show}>
              <Slider
                {...settings}
                style={{
                  width: !isMobile ? "550px" : "239px",
                  height: !isMobile ? "400px" : "170px",
                }}
                // className={styles.modalSlider}
              >
                {selectedCar.imageUrls.map((url) => (
                  <AspectRatio
                    ratio="4/3"
                    style={{
                      width: !isMobile ? "550px" : "257px",
                      height: !isMobile ? "400px" : "170px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={url.path}
                      style={{
                        borderRadius: "8px",
                        width: !isMobile ? "550px" : "257px",
                        height: !isMobile ? "400px" : "170px",
                      }}
                      alt="car"
                      key={`${url.id}${url.path}`}
                    />
                  </AspectRatio>
                ))}
              </Slider>
            </Modal>
          </div>
          <div className={styles.carDescriptionTextBlock}>
            <div className={styles.carDescriptionTextContainer}>
              <span
                className={styles.carModel}
                style={{
                  color: fontColor,
                }}
              >
                {selectedCar.make} {selectedCar.model}
              </span>

              <div className={styles.detailedDescription}>
                <div className={styles.detailedDescriptionTitleContainer}>
                  <span
                    className={styles.detailedDescriptionTitleSelf}
                    // style={{
                    //   color: fontColor,
                    // }}
                  >
                    Type
                  </span>
                </div>
                {/* <div
                      className={styles.detailedDescriptionPointedLineContainer}
                    >
                      <div
                        className={styles.detailedDescriptionPointedLineSelf}
                        style={{
                          borderBottom: `2px dotted ${fontColor}`,
                        }}
                      />
                    </div> */}
                <div className={styles.detailedDescriptionValueContainer}>
                  <span
                    className={styles.detailedDescriptionValueSelf}
                    style={{
                      color: fontColor,
                    }}
                  >
                    {selectedCar.type}
                  </span>
                </div>
              </div>

              <div className={styles.detailedDescription}>
                <div className={styles.detailedDescriptionTitleContainer}>
                  <span
                    className={styles.detailedDescriptionTitleSelf}
                    // style={{
                    //   color: fontColor,
                    // }}
                  >
                    Capacity
                  </span>
                </div>
                {/* <div
                      className={styles.detailedDescriptionPointedLineContainer}
                    >
                      <div
                        className={styles.detailedDescriptionPointedLineSelf}
                        style={{
                          borderBottom: `2px dotted ${fontColor}`,
                        }}
                      />
                    </div> */}
                <div className={styles.detailedDescriptionValueContainer}>
                  <span
                    className={styles.detailedDescriptionValueSelf}
                    style={{
                      color: fontColor,
                    }}
                  >
                    {selectedCar.capacity}
                  </span>
                </div>
              </div>

              <div className={styles.detailedDescription}>
                <div className={styles.detailedDescriptionTitleContainer}>
                  <span
                    className={styles.detailedDescriptionTitleSelf}
                    // style={{
                    //   color: fontColor,
                    // }}
                  >
                    Color
                  </span>
                </div>
                {/* <div
                      className={styles.detailedDescriptionPointedLineContainer}
                    >
                      <div
                        className={styles.detailedDescriptionPointedLineSelf}
                        style={{
                          borderBottom: `2px dotted ${fontColor}`,
                        }}
                      />
                    </div> */}
                <div className={styles.detailedDescriptionValueContainer}>
                  <span
                    className={styles.detailedDescriptionValueSelf}
                    style={{
                      color: fontColor,
                    }}
                  >
                    {selectedCar.color}
                  </span>
                </div>
              </div>

              <div className={styles.detailedDescription}>
                <div className={styles.detailedDescriptionTitleContainer}>
                  <span
                    className={styles.detailedDescriptionTitleSelf}
                    // style={{
                    //   color: fontColor,
                    // }}
                  >
                    Amount
                  </span>
                </div>
                {/* <div
                      className={styles.detailedDescriptionPointedLineContainer}
                    >
                      <div
                        className={styles.detailedDescriptionPointedLineSelf}
                        style={{
                          borderBottom: `2px dotted ${fontColor}`,
                        }}
                      />
                    </div> */}
                <div className={styles.detailedDescriptionValueContainer}>
                  <span
                    className={styles.detailedDescriptionValueAmountSelf}
                    style={{
                      color: fontColor,
                    }}
                  >
                    {gateMeeting
                      ? `$${round(
                          selectedCar.price -
                            selectedCar.greetAndMeetPrice -
                            selectedCar.boosterSeatPrice -
                            selectedCar.safetySeatPrice,
                          2
                        )}`
                      : showCarAmount()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
          }}
        >
          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  Date:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {formData.orderStartDateTime.match(
                    /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  Time:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {new Date(formData.orderStartDateTime).toLocaleTimeString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                    }
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  From:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {formData.orderAddressDetails[0].rideCheckPoint}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  To:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {
                    formData.orderAddressDetails[
                      formData.orderAddressDetails.length - 1
                    ].rideCheckPoint
                  }
                </span>
              </div>
            </div>
          </div>
          {formData.isRoundTrip && (
            <>
              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      style={{
                        // color: fontColor,
                        fontSize: "19px",
                      }}
                    >
                      Return
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      //   style={{
                      //     color: fontColor,
                      //   }}
                    >
                      Date:
                    </span>
                  </div>
                  {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
                  <div className={styles.reservationDetailsItemValueContainer}>
                    <span
                      className={styles.reservationDetailsItemValueSelf}
                      style={{
                        color: fontColor,
                      }}
                    >
                      {formData.returnStartDateTime?.match(
                        /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      //   style={{
                      //     color: fontColor,
                      //   }}
                    >
                      Time:
                    </span>
                  </div>
                  {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
                  <div className={styles.reservationDetailsItemValueContainer}>
                    <span
                      className={styles.reservationDetailsItemValueSelf}
                      style={{
                        color: fontColor,
                      }}
                    >
                      {new Date(
                        formData.returnStartDateTime
                      )?.toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      //   style={{
                      //     color: fontColor,
                      //   }}
                    >
                      From:
                    </span>
                  </div>
                  {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
                  <div className={styles.reservationDetailsItemValueContainer}>
                    <span
                      className={styles.reservationDetailsItemValueSelf}
                      style={{
                        color: fontColor,
                      }}
                    >
                      {formData.returnOrderAddressDetails[0]?.rideCheckPoint}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      //   style={{
                      //     color: fontColor,
                      //   }}
                    >
                      To:
                    </span>
                  </div>
                  {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
                  <div className={styles.reservationDetailsItemValueContainer}>
                    <span
                      className={styles.reservationDetailsItemValueSelf}
                      style={{
                        color: fontColor,
                      }}
                    >
                      {
                        formData.returnOrderAddressDetails[
                          formData.returnOrderAddressDetails?.length - 1
                        ]?.rideCheckPoint
                      }
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  Vehicle:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {selectedCar.type}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  Total distance:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {distance} miles
                </span>
              </div>
            </div>
          </div>

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  Number of Passengers:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {formData.passengersQuantity}
                </span>
              </div>
            </div>
          </div>

          <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  //   style={{
                  //     color: fontColor,
                  //   }}
                >
                  Transaction Fee:
                </span>
              </div>
              {/* <div
                className={styles.reservationDetailsItemPointedLineContainer}
              >
                <div
                  className={styles.reservationDetailsItemPointedLineSelf}
                  style={{
                    borderBottom: `2px dotted ${fontColor}`,
                  }}
                />
              </div> */}
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {`$${
                    formData?.Tips?.Amount == 18
                      ? selectedCar.transactionFee
                      : formData.feeAmount
                  }`}
                </span>
              </div>
            </div>
          </div>

          {hourlyAndSeatsRedux && (
            <div className={styles.reservationDetailsItem}>
              <div className={styles.reservationDetailsItemContainer}>
                <div className={styles.reservationDetailsItemTitleContainer}>
                  <span
                    className={styles.reservationDetailsItemTitleSelf}
                    // style={{
                    //   color: fontColor,
                    // }}
                  >
                    Hours:
                  </span>
                </div>
                {/* <div
                  className={styles.reservationDetailsItemPointedLineContainer}
                >
                  <div
                    className={styles.reservationDetailsItemPointedLineSelf}
                    style={{
                      borderBottom: `2px dotted ${fontColor}`,
                    }}
                  />
                </div> */}
                <div className={styles.reservationDetailsItemValueContainer}>
                  <span
                    className={styles.reservationDetailsItemValueSelf}
                    style={{
                      color: fontColor,
                    }}
                  >
                    {formData.hours}
                  </span>
                </div>
              </div>
            </div>
          )}

          <>
            {selectedCar.boosterSeatPrice == undefined
              ? null
              : formData.showCarsWithSafetySeat == true && (
                  <div className={styles.reservationDetailsItem}>
                    <div className={styles.reservationDetailsItemContainer}>
                      <div
                        className={styles.reservationDetailsItemTitleContainer}
                      >
                        <span
                          className={styles.reservationDetailsItemTitleSelf}
                          //   style={{
                          //     color: fontColor,
                          //   }}
                        >
                          Youth Booster Seat:
                        </span>
                      </div>
                      {/* <div
                        className={
                          styles.reservationDetailsItemPointedLineContainer
                        }
                      >
                        <div
                          className={
                            styles.reservationDetailsItemPointedLineSelf
                          }
                          style={{
                            borderBottom: `2px dotted ${fontColor}`,
                          }}
                        />
                      </div> */}
                      <div
                        className={styles.reservationDetailsItemValueContainer}
                      >
                        <span
                          className={styles.reservationDetailsItemValueSelf}
                          style={{
                            color: fontColor,
                          }}
                        >
                          {selectedCar.boosterSeatPrice !== 0 &&
                          selectedCar.boosterSeatPrice !== undefined
                            ? `$${selectedCar.boosterSeatPrice}`
                            : `$${0}`}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
          </>

          <>
            {selectedCar.safetySeatPrice == undefined
              ? null
              : formData.showCarsWithSafetySeat == true && (
                  <div className={styles.reservationDetailsItem}>
                    <div className={styles.reservationDetailsItemContainer}>
                      <div
                        className={styles.reservationDetailsItemTitleContainer}
                      >
                        <span
                          className={styles.reservationDetailsItemTitleSelf}
                          //   style={{
                          //     color: fontColor,
                          //   }}
                        >
                          {"Infant & Child Safety Seat:"}
                        </span>
                      </div>
                      {/* <div
                        className={
                          styles.reservationDetailsItemPointedLineContainer
                        }
                      >
                        <div
                          className={
                            styles.reservationDetailsItemPointedLineSelf
                          }
                          style={{
                            borderBottom: `2px dotted ${fontColor}`,
                          }}
                        />
                      </div> */}
                      <div
                        className={styles.reservationDetailsItemValueContainer}
                      >
                        <span
                          className={styles.reservationDetailsItemValueSelf}
                          style={{
                            color: fontColor,
                          }}
                        >
                          {selectedCar.safetySeatPrice !== 0 &&
                          selectedCar.safetySeatPrice !== undefined
                            ? `$${selectedCar.safetySeatPrice}`
                            : `$${0}`}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
          </>

          {gateMeeting && (
            <>
              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      //   style={{
                      //     color: fontColor,
                      //   }}
                    >
                      {"Meet & Greet/Luggage Assist:"}
                    </span>
                  </div>
                  {/* <div
                    className={
                      styles.reservationDetailsItemPointedLineContainer
                    }
                  >
                    <div
                      className={styles.reservationDetailsItemPointedLineSelf}
                      style={{
                        borderBottom: `2px dotted ${fontColor}`,
                      }}
                    />
                  </div> */}
                  <div className={styles.reservationDetailsItemValueContainer}>
                    <span
                      className={styles.reservationDetailsItemValueSelf}
                      style={{
                        color: fontColor,
                      }}
                    >
                      {`$${selectedCar.greetAndMeetPrice}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.reservationDetailsItem}>
                <div className={styles.reservationDetailsItemContainer}>
                  <div className={styles.reservationDetailsItemTitleContainer}>
                    <span
                      className={styles.reservationDetailsItemTitleSelf}
                      //   style={{
                      //     color: fontColor,
                      //   }}
                    >
                      Luggage count:
                    </span>
                  </div>
                  {/* <div
                    className={
                      styles.reservationDetailsItemPointedLineContainer
                    }
                  >
                    <div
                      className={styles.reservationDetailsItemPointedLineSelf}
                      style={{
                        borderBottom: `2px dotted ${fontColor}`,
                      }}
                    />
                  </div> */}
                  <div className={styles.reservationDetailsItemValueContainer}>
                    <span
                      className={styles.reservationDetailsItemValueSelf}
                      style={{
                        color: fontColor,
                      }}
                    >
                      {formData.luggageCount}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className={styles.reservationDetailsItem}
            // style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <div className={styles.reservationDetailsItemContainer}>
              <div
                className={styles.reservationDetailsItemTitleContainer}
                style={{
                  // marginRight: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <span className={styles.reservationDetailsItemTitleSelf}>
                  Gratuity
                </span>
                <div
                  className={styles.dropDownIconContainer}
                  style={{ background: dropDownButtonBackroundColor }}
                  onClick={() => {
                    setShowGratuityDropdown(!showGratuityDropdown)
                  }}
                >
                  <span
                    className={styles.dropDownIcon}
                    style={{
                      color: dropDownButtonFontColor,
                    }}
                  >
                    {">"}
                  </span>
                </div>
              </div>

              <div
                className={styles.reservationDetailsItemValueContainer}
                // onClick={(event) => {
                //   event.stopPropagation()
                // }}
              >
                <div className={styles.gratuityValueInput}>
                  {/* {!formData?.isCustomTips && ( */}
                  <span
                    className={styles.gratuityValue}
                    style={{
                      color: fontColor,
                    }}
                  >
                    $
                    {!formData?.isCustomTips &&
                      formData?.Tips?.Amount == 18 &&
                      round(selectedCar.tipsAmount, 2)}
                    {!formData?.isCustomTips &&
                      formData?.Tips?.Amount !== 18 &&
                      round(formData.tipsAmount, 2)}
                    {formData?.isCustomTips &&
                      formData?.Tips?.Amount &&
                      formData?.Tips?.Amount}
                    {/* {formData?.isCustomTips &&
                      !formData?.Tips?.Amount &&
                      formData?.Tips} */}
                  </span>
                  {/* )} */}
                  {/* {formData?.isCustomTips && (
                    <>
                      <div
                        className={styles.customDollarAndInputContainer}
                        style={{ borderBottom: `1px solid ${fontColor}` }}
                      >
                        <span
                          className={styles.gratuityValue}
                          style={{
                            fontSize: "15px",
                            color: fontColor,
                            marginRight: formData?.isCustomTips && "-26px",
                          }}
                        >
                          $
                        </span>
                        <input
                          autofocus="autofocus"
                          style={{
                            width: "26px",
                            height: "20px",
                            padding: 0,
                            background: "transparent",
                            border: "none",
                            fontSize: "15px",

                            outline: "none",

                            // marginRight: formData?.isCustomTips && "46px",
                            // textAlign: "right",
                            color: fontColor,
                          }}
                          type="number"
                          value={
                            formData?.Tips?.Amount
                              ? formData?.Tips?.Amount
                              : formData?.Tips
                          }
                          onChange={(event) => {
                            setCustomTipsInputValue(event.target.value)
                            setTipsPercentage({
                              AmountType: 1,
                              Amount: Number(`${event.target.value}`),
                            })
                          }}
                          onClick={() => {
                            setOpenCustomTipsModal(true)
                          }}
                        />
                      </div>
                      {openCustomTipsModal && (
                        <div
                          className={styles.okAndXContainer}
                          style={{
                            border: redBorderToCustomTipsOkButton
                              ? "1px solid red"
                              : "none",
                          }}
                        >
                          <button
                            className={styles.okCustomButton}
                            style={{
                              width: "52px",
                              height: "23px",
                              marginRight: "-14px",
                              padding: "0",
                              border: "none",
                              background: customTipsOkButtonBackground,
                              color: customTipsOkButtonColor,
                              // borderRadius: "3px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setGratuity(formData?.Tips?.Amount, 1)

                              setOpenCustomTipsModal(false)
                            }}
                          >
                            OK
                          </button>
                          <div
                            className={styles.xCustomButton}
                            style={{
                              width: "52px",
                              height: "23px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: customTipsCancelButtonBackground,
                              // color: dropDownButtonFontColor,
                            }}
                            onClick={() => {
                              setGratuity(18)
                              setIsCustomTips(false)

                              setOpenCustomTipsModal(false)
                            }}
                          >
                            <CloseCustomTipsIcon
                              color={customTipsCancelButtonColor}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )} */}
                </div>
                {showGratuityDropdown && (
                  <div
                    className={styles.dropDownContainer}
                    style={{ background: dropDownButtonBackroundColor }}
                  >
                    <span
                      className={styles.dropDownItem}
                      style={{
                        background:
                          !formData?.isCustomTips &&
                          formData?.Tips?.Amount == 0 &&
                          dropDownItemHoverAndSelectBackgroundColor,
                        color: dropDownItemHoverAndSelectFontColor,
                      }}
                      onClick={(event) => {
                        setGratuityDropdownValue(0)
                        setGratuity(0)
                        setIsCustomTips(false)
                        // dispatch(setTips(event.target.innerText))
                        console.log(event.target.innerText)
                      }}
                    >
                      <span
                        className={styles.selectedIcon}
                        style={{ color: dropDownButtonBackroundColor }}
                      >
                        {!formData?.isCustomTips &&
                          formData?.Tips?.Amount == 0 &&
                          ">"}
                      </span>
                      None
                    </span>
                    <span
                      // style={{ background: gratuityDropdownValue == '15%' ?  }}
                      style={{
                        background:
                          !formData?.isCustomTips &&
                          formData?.Tips?.Amount == 15 &&
                          dropDownItemHoverAndSelectBackgroundColor,
                        color: dropDownItemHoverAndSelectFontColor,
                      }}
                      className={styles.dropDownItem}
                      onClick={(event) => {
                        setGratuityDropdownValue(event.target.innerText)
                        // dispatch(setTips(event.target.innerText))
                        setGratuity(15)
                        setIsCustomTips(false)
                        console.log(event.target.innerText)
                      }}
                    >
                      <span
                        className={styles.selectedIcon}
                        style={{ color: dropDownButtonBackroundColor }}
                      >
                        {!formData?.isCustomTips &&
                          formData?.Tips?.Amount == 15 &&
                          ">"}
                      </span>
                      15%
                    </span>
                    <span
                      style={{
                        background:
                          !formData?.isCustomTips &&
                          formData?.Tips?.Amount == 18 &&
                          dropDownItemHoverAndSelectBackgroundColor,
                        color: dropDownItemHoverAndSelectFontColor,
                      }}
                      className={styles.dropDownItem}
                      onClick={(event) => {
                        setGratuityDropdownValue(event.target.innerText)
                        // dispatch(setTips(event.target.innerText))
                        setGratuity(18)
                        setIsCustomTips(false)
                        console.log(event.target.innerText)
                      }}
                    >
                      <span
                        className={styles.selectedIcon}
                        style={{ color: dropDownButtonBackroundColor }}
                      >
                        {!formData?.isCustomTips &&
                          formData?.Tips?.Amount == 18 &&
                          ">"}
                      </span>
                      18%
                    </span>
                    <span
                      style={{
                        background:
                          !formData?.isCustomTips &&
                          formData?.Tips?.Amount == 20 &&
                          dropDownItemHoverAndSelectBackgroundColor,
                        color: dropDownItemHoverAndSelectFontColor,
                      }}
                      className={styles.dropDownItem}
                      onClick={(event) => {
                        setGratuityDropdownValue(event.target.innerText)
                        // dispatch(setTips(event.target.innerText))
                        setGratuity(20)
                        setIsCustomTips(false)
                        console.log(event.target.innerText)
                      }}
                    >
                      <span
                        className={styles.selectedIcon}
                        style={{ color: dropDownButtonBackroundColor }}
                      >
                        {!formData?.isCustomTips &&
                          formData?.Tips?.Amount == 20 &&
                          ">"}
                      </span>
                      20%
                    </span>
                    <span
                      style={{
                        background:
                          !formData?.isCustomTips &&
                          formData?.Tips?.Amount == 25 &&
                          dropDownItemHoverAndSelectBackgroundColor,
                        color: dropDownItemHoverAndSelectFontColor,
                      }}
                      className={styles.dropDownItem}
                      onClick={(event) => {
                        setGratuityDropdownValue(event.target.innerText)
                        // dispatch(setTips(event.target.innerText))
                        setGratuity(25)
                        setIsCustomTips(false)
                        console.log(event.target.innerText)
                      }}
                    >
                      <span
                        className={styles.selectedIcon}
                        style={{ color: dropDownButtonBackroundColor }}
                      >
                        {!formData?.isCustomTips &&
                          formData?.Tips?.Amount == 25 &&
                          ">"}
                      </span>
                      25%
                    </span>
                    <span
                      className={styles.dropDownItem}
                      style={{
                        background:
                          formData?.isCustomTips &&
                          dropDownItemHoverAndSelectBackgroundColor,
                        color: dropDownItemHoverAndSelectFontColor,
                      }}
                      onClick={(event) => {
                        // setGratuityDropdownValue(0)
                        // setGratuity(customTipsAmount, "custom")

                        setTipsPercentage({
                          Tips: {
                            AmountType: 1,
                            Amount: null,
                          },
                        })
                        setIsCustomTips(true)
                        setOpenCustomTipsModal(true)
                        // dispatch(setTips(event.target.innerText))
                        console.log(event.target.innerText)
                      }}
                    >
                      <span
                        className={styles.selectedIcon}
                        style={{ color: dropDownButtonBackroundColor }}
                      >
                        {formData?.isCustomTips && ">"}
                      </span>
                      Custom
                    </span>
                  </div>
                )}
              </div>
            </div>
            <ModalForCustomTips
              onClose={() => {
                setOpenCustomTipsModal(false)
                setGratuity(18)
                // setIsCustomTips(false)
                setIsCustomTips(false)
                setOpenCustomTipsModal(false)
                setRedBorderToCustomTipsOkButton(false)
                setShowGratuityDropdown(false)
              }}
              show={openCustomTipsModal}
            >
              {/* {formData?.isCustomTips && ( */}
              <div className={styles.customTipsModalContainer}>
                {/* {formData?.isCustomTips && ( */}
                <p
                  style={{
                    color: "white",
                    margin: "0",
                    marginBottom: "10.3px",
                  }}
                >
                  Gratuity
                </p>
                <div
                  className={styles.customDollarAndInputContainer}
                  style={{
                    border: redBorderToCustomTipsOkButton && "1px solid red",
                    background: customTipsInputBackground,
                  }}
                >
                  {/* <span
                  className={styles.gratuityValue}
                  style={{
                    color: "white",
                    // marginRight: formData?.isCustomTips && "-30px",
                  }}
                >
                  $
                </span> */}
                  <input
                    autofocus
                    style={{
                      width: "100%",
                      height: "39px",
                      background: "#000000",
                      border: "none",
                      // width: "26px",
                      border: "1px solid #282828",
                      boxShadow: "0px 0px 1.66916px rgba(0, 0, 0, 0.25)",
                      borderRadius: "3.45349px",
                      fontSize: "15px",
                      outline: "none",
                      padding: 0,
                      textAlign: "center",
                      // textAlign: "right",
                      color: "white",
                    }}
                    id="customTipsInput"
                    // type="number"
                    // name="currency-input"
                    placeholder={currentcyFormat.format("")}
                    onKeyDown={keyPressHanlder}
                    value={
                      customTipsInputValue !== ""
                        ? currentcyFormat.format(customTipsInputValue)
                        : ""
                    }
                    // onChange={(event) => {
                    //   setCustomTipsInputValue(event.target.value)
                    //   setTipsPercentage({
                    //     AmountType: 1,
                    //     Amount: Number(`${event.target.value}`),
                    //   })
                    // }}
                  />
                </div>
                {/* )} */}
                <div
                  className={styles.okAndXContainer}
                  // style={{
                  //   border: redBorderToCustomTipsOkButton ? "1px solid red" : "none",
                  // }}
                >
                  <button
                    className={styles.xCustomButton}
                    style={{
                      width: "48%",
                      height: "39px",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      padding: "0",
                      border: "none",
                      borderRadius: "3.5px",
                      background: "#282828",
                      color: "white",
                      cursor: "pointer",
                      fontSize: "16px",
                      // color: dropDownButtonFontColor,
                    }}
                    onClick={() => {
                      setGratuity(18)
                      // setIsCustomTips(false)
                      setIsCustomTips(false)
                      setOpenCustomTipsModal(false)
                      setRedBorderToCustomTipsOkButton(false)
                      setShowGratuityDropdown(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={styles.okCustomButton}
                    style={{
                      width: "48%",
                      height: "39px",
                      // marginRight: "-14px",
                      padding: "0",
                      border: "none",
                      borderRadius: "3.5px",
                      background: "#282828",
                      color: "white",
                      // borderRadius: "3px",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      if (customTipsInputValue) {
                        setGratuity(
                          formData?.Tips?.Amount
                            ? formData?.Tips?.Amount
                            : customTipsInputValue,
                          1
                        )
                        // setIsCustomTips(false)
                        setOpenCustomTipsModal(false)
                        setRedBorderToCustomTipsOkButton(false)
                        setShowGratuityDropdown(false)
                      } else {
                        setRedBorderToCustomTipsOkButton(true)
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </ModalForCustomTips>
          </div>
        </div>
        {/* <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  Total:
                </span>
              </div>
              
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {`$${round(selectedCar.price, 2)}`}
                </span>
              </div>
            </div>
          </div> */}

        <div className={styles.notesTextFieldContainer}>
          {!orderNotesRedux ? (
            <Textarea
              id="outlined-multiline-flexible"
              placeholder="Notes / Itinerary"
              // value={orderNotesRedux}
              onChange={(event) => {
                setNoteRedux(event.target.value)
              }}
              fontColor={fontColor}
            />
          ) : (
            <TextareaWithDefaultValue
              // value={orderNotesRedux}
              fontColor={fontColor}
            >
              {orderNotesRedux}
            </TextareaWithDefaultValue>
          )}
        </div>
        <div
          className={styles.detailedDescriptionWrapperTotal}
          style={{
            borderTop: `1px solid grey`,
            // borderBottom: `1px solid grey`,
          }}
        >
          <div className={styles.detailedDescriptionTotal}>
            <div className={styles.detailedDescriptionTitleContainerTotal}>
              <span
                className={styles.detailedDescriptionTitleSelf}
                style={{
                  fontSize: "19px",
                  marginRight: "25px",
                  fontWeight: "700",
                }}
              >
                Total:
              </span>
            </div>
            {/* <div
                      className={styles.detailedDescriptionPointedLineContainer}
                    >
                      <div
                        className={styles.detailedDescriptionPointedLineSelf}
                        style={{
                          borderBottom: `2px dotted ${fontColor}`,
                        }}
                      />
                    </div> */}
            <div className={styles.detailedDescriptionValueContainerTotal}>
              <span
                className={styles.detailedDescriptionValueAmountSelf}
                style={{
                  color: fontColor,
                  fontSize: "19px",
                }}
              >
                {`$${
                  formData?.Tips?.Amount == 18
                    ? round(
                        selectedCar.price +
                          selectedCar.transactionFee +
                          selectedCar.tipsAmount,
                        2
                      )
                    : round(formData.totalAmount, 2)
                }`}
              </span>
            </div>
          </div>
        </div>

        {/* <div className={styles.reservationDetailsItem}>
            <div className={styles.reservationDetailsItemContainer}>
              <div className={styles.reservationDetailsItemTitleContainer}>
                <span
                  className={styles.reservationDetailsItemTitleSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  Total:
                </span>
              </div>
              <div className={styles.reservationDetailsItemValueContainer}>
                <span
                  className={styles.reservationDetailsItemValueSelf}
                  style={{
                    color: fontColor,
                  }}
                >
                  {`$${round(selectedCar.price, 2)}`}
                </span>
              </div>
            </div>
          </div> */}
      </div>
    </>
  )
}

export default PreviewReusableUIComponent

const Textarea = styled.textarea`
  width: 100%;
  // display: block;
  height: 45px;
  padding-top: 10px;
  color: ${(props) => props.fontColor};
  font-family: "Vazir", sans-serif;
  font-size: 14px;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
  background: transparent;
  box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.08);
  text-indent: 20px;

  /* border-bottom: 1px solid ${(props) => props.fontColor}; */
  border-radius: 15px;
  &::-webkit-input-placeholder {
    font-size: 15px;
    color: ${(props) => props.fontColor};
    font-family: "Vazir", sans-serif;
  }
  &:-moz-placeholder {
    /* Upto Firefox 18, Deprecated in Firefox 19  */
    font-size: 15px;
    color: ${(props) => props.fontColor};
    font-family: "Vazir", sans-serif;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 15px;
    color: ${(props) => props.fontColor};
    font-family: "Vazir", sans-serif;
  }
  &:-ms-input-placeholder {
    font-size: 15px;
    color: ${(props) => props.fontColor};
    font-family: "Vazir", sans-serif;
  }
  &:focus::placeholder {
    color: transparent;
  }
`
const TextareaWithDefaultValue = styled.div`
  width: 100%;
  // display: block;
  box-sizing: border-box;
  min-height: 45px;
  padding: 10px 18px;
  color: ${(props) => props.fontColor};
  font-family: "Vazir", sans-serif;
  font-size: 14px;
  border: none;
  outline: none;
  resize: none;
  /* padding: 0; */
  background: transparent;
  box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.08);
  /* text-indent: 20px; */
  word-break: break-all;
  white-space: normal;
  /* border-bottom: 1px solid ${(props) => props.fontColor}; */
  border-radius: 15px;
`
