import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { placesApi } from "../../../../api/api"
import { getCarsByType, getCompanyCars } from "../../../../Redux/car-reducer"
import Carousel, { consts } from "react-elastic-carousel"
// import {
//   LeftArrowForAdressForm,
//   RightArrowForAdressForm,
// } from "../../../../assets/icons"
// import Button from "@material-ui/core/Button"
import { useStyles } from "./AdressFormStyles"
// import { useMediaQuery } from "@material-ui/core"
import { connect } from "react-redux"
import {
  setBoosterSeatCount,
  setDateForDefaultValue,
  setFormData,
  setHoursRedux,
  setPassengersQuantityForBackStep,
  setSafetySeatCount,
  setShowCarsWithSafetySeat,
  setTimeForDefaultValue,
  setTimeForDefaultValueAlignment,
  setTimeForDefaultValueAMPM,
  setIsRoundTrip,
  setReturnDate,
  setReturnTime,
  setReturnTimeAMPM,
  setReturnTimeAMPMAlignment,
  setIsReturnAirportPickupIncludedRedux,
} from "../../../../Redux/form-reducer"

import { setHourlyRedux } from "../../../../Redux/hourly-reducer"
import {
  setGateMeetingRedux,
  setReturnGateMeetingRedux,
} from "../../../../Redux/gate-meeting-reducer"
// import { AdressFormHelpers } from "./adressFormHelpers"
import AdressFormUIComponent from "./AdressFormUIComponent"
import styles from "./AdressFormStyles/AdressForm.module.scss"
import * as yup from "yup"
import ThemeContext from "../../../../context"
import styled from "styled-components"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"

import { EndLocationIcon, StartLocationIcon } from "../../../../assets/icons"
import { GoogleApiWrapper } from "google-maps-react"

const AdressFormContainerComponent = ({
  next,
  carTypes,
  pageSize,
  getCompanyCars,
  setFormData,
  formData,
  setHourlyRedux,
  setGateMeetingRedux,
  setReturnGateMeetingRedux,
  gateMeeting,
  returnGateMeeting,
  hourlyAndSeatsRedux,
  setSafetySeatCount,
  setBoosterSeatCount,
  backgroundScrollStopForTimePicker,
  setBackgroundScrollStopForTimePicker,
  resetInputs,
  setDateForDefaultValue,
  setTimeForDefaultValue,
  setTimeForDefaultValueAMPM,
  setTimeForDefaultValueAlignment,

  setReturnDate,
  setReturnTime,
  setReturnTimeAMPM,
  setReturnTimeAMPMAlignment,

  setPassengersQuantityForBackStep,
  isBoosterSeatExistOnBackend,
  isSafetySeatExistOnBackend,
  setShowCarsWithSafetySeat,
  setIsRoundTrip,
  setHoursRedux,
  hoursCount,
  accessKey,
  setIsReturnAirportPickupIncludedRedux,
}) => {
  // const classes = useStyles()

  const [destinations, setDestinations] = useState([
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
  ])

  const [returnDestinations, setReturnDestinations] = useState([
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
  ])

  const [carSelectionID, setCarSelectionID] = useState(0)
  const [bookingType, setBookingType] = useState(1)
  const [passengers, setPassengers] = useState(0)
  const [hoursAddressForm, setHoursAddressForm] = useState(0)
  const [disableHourly, setDisableHourly] = useState(false)
  const [hourly, setHourly] = useState(false)

  const [isGateMeeting, setIsGateMeeting] = useState(false)
  const [isReturnGateMeeting, setIsReturnGateMeeting] = useState(false)
  const [isAirline, setIsAirline] = useState(false)
  const [isReturnAirline, setIsReturnAirline] = useState(false)
  const [airlineId, setAirlineId] = useState(0)
  const [returnAirlineId, setReturnAirlineId] = useState(0)
  const [airlines, setAirlines] = useState([])
  const [airlineName, setAirlineNAme] = useState([])

  const [redBorderOnSubmit, setRedBorderOnSubmit] = useState(false)
  const [redBorderOnSubmit2, setRedBorderOnSubmit2] = useState(false)
  const [redBorderOnSubmitForDate, setRedBorderOnSubmitForDate] =
    useState(false)
  const [redBorderOnSubmitForTime, setRedBorderOnSubmitForTime] =
    useState(false)
  const [redBorderOnSubmitForTime2, setRedBorderOnSubmitForTime2] =
    useState(false)
  const [redBorderOnSubmitForTime3, setRedBorderOnSubmitForTime3] =
    useState(false)
  const [redBorderOnSubmitForTime4, setRedBorderOnSubmitForTime4] =
    useState(false)
  const [redBorderOnSubmitForReturnDate, setRedBorderOnSubmitForReturnDate] =
    useState(false)
  const [redBorderOnSubmitForReturnTime, setRedBorderOnSubmitForReturnTime] =
    useState(false)
  const [redBorderOnSubmitForReturnTime2, setRedBorderOnSubmitForReturnTime2] =
    useState(false)
  const [redBorderOnSubmitForReturnTime3, setRedBorderOnSubmitForReturnTime3] =
    useState(false)
  const [redBorderOnSubmitForReturnTime4, setRedBorderOnSubmitForReturnTime4] =
    useState(false)
  const [redBorderOnSubmitForCarType, setRedBorderOnSubmitForCarType] =
    useState(false)
  const [redBorderOnSubmitForPassengers, setRedBorderOnSubmitForPassengers] =
    useState(false)
  const [redBorderOnAirlines, setRedBorderOnAirlines] = useState(false)
  const [redBorderOnSubmitForHours, setRedBorderOnSubmitForHours] =
    useState(false)
  const [
    isAirportPickupIncludedLocalState,
    setIsAirportPickupIncludedLocalState,
  ] = useState(false)

  const [
    isReturnAirportPickupIncludedLocalState,
    setReturnIsAirportPickupIncludedLocalState,
  ] = useState(false)

  const [time, setTime] = useState("")

  const [returnTimeLocal, setReturnTimeLocal] = useState("")

  const startsWithTwo = time[0] === "2"

  const [timeMask, setTimeMask] = useState(false)

  const [date, setDate] = React.useState(null)

  const [returnDateLocal, setReturnDateLocal] = React.useState(null)

  const [showDatePickerCalendarModal, setShowDatePickerCalendarModal] =
    useState(false)

  const [
    showReturnDatePickerCalendarModal,
    setShowReturnDatePickerCalendarModal,
  ] = useState(false)

  const [showRecaptcha, setShowRecaptcha] = useState(false)

  const [luggage, setLuggage] = useState(0)

  const setUseHookState = (value, id) => {
    let newArr = [...returnDestinations]
    newArr[id].rideCheckPoint = value
    setReturnDestinations(newArr)
  }

  const handleChangeAddress = (value, id) => {
    setUseHookState(value, id)
  }

  let selectedArray = null

  const handleSelect = async (value, id) => {
    setUseHookState(value, id)
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    const placeId = results[0].place_id
    let placeType = 0
    if (results[0].types.some((types) => types === "airport")) {
      placeType = 2
    }
    selectedArray = [...returnDestinations]
    selectedArray[id] = {
      ...selectedArray[id],
      latitude: latLng.lat,
      longitude: latLng.lng,
      placeId: placeId,
      placeType: placeType,
    }

    setReturnDestinations(selectedArray)
  }

  const addEndPoint = () => {
    let newArr = [
      ...returnDestinations,
      {
        rideCheckPoint: "",
        latitude: 0,
        longitude: 0,
        placeType: 0,
        placeId: "",
      },
    ]
    setReturnDestinations(newArr)
  }

  const removeEndPoint = (index) => {
    let newArr = [...returnDestinations]
    newArr.splice(index, 1)
    setReturnDestinations(newArr)
  }
  var copiedSuggestions = null
  var copiedLoading = null
  React.useEffect(() => {
    if (Boolean(formData.returnOrderAddressDetails[0]?.rideCheckPoint)) {
      setReturnDestinations(formData.returnOrderAddressDetails)
    }
    if (resetInputs) {
      setReturnDestinations(returnDestinations)
    }
  }, [])

  const setDestinationsIcons = (id, destinations) => {
    if (id === 0) {
      return styles.destinationsInputWithFROMIcon
    } else if (id === destinations.length - 1) {
      return styles.destinationsInputWithTOIcon
    }
    if (id > 0 && id < destinations.length - 1) {
      return styles.destinationsInputWithNumberIcon
    }
  }
  const getDestinationsIcons = (id, destinations) => {
    if (id === 0) {
      return <StartLocationIcon color={inputsFontColor} />
    } else if (id === destinations.length - 1) {
      return <EndLocationIcon color={inputsFontColor} />
    }
  }

  // const extractAirlineId = (name) => {
  //   const res = airlines.find((element, index, array) => {
  //     return element.name == name
  //   })
  //   res ? setAirlineId(res.id) : setAirlineId(null)
  //   res ? setReturnAirlineId(res.id) : setReturnAirlineId(null)

  //   setAirlineNAme(name)
  //   // console.log(res)
  // }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    ...methods
  } = useForm({})

  const handleClick = (id) => {
    setCarSelectionID(id)
  }
  const isHourlyEnabled = () => {
    if (hourly || hourlyAndSeatsRedux) {
      if (hoursCount) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  let firstAirline =
    destinations[0]?.rideCheckPoint.match(/(^|\W)Airport($|\W)/)

  let filteredReturnDestinationsToGetAirline = returnDestinations.filter(
    (item) => {
      return item.rideCheckPoint.match(/(^|\W)Airport($|\W)/)
    }
  )

  let returnAirline =
    filteredReturnDestinationsToGetAirline[0]?.rideCheckPoint?.match(
      /(^|\W)Airport($|\W)/
    )

  // const fetchAirlines = async () => {
  //   const data = await placesApi.getAirlines()
  //   const res = data.map((airline) => {
  //     return {
  //       id: `${airline.id}`,
  //       name: `${airline.code} ` + `${airline.name}`,
  //     }
  //   })
  //   setAirlines(res)
  // }

  const [flightNumber, setFlightNumber] = useState(null)
  const [returnFlightNumber, setReturnFlightNumber] = useState(null)

  const {
    ThemeProviderAppBackgroundColor,
    fontColor,
    borderRadiuses,
    carsTypeColor,
    hoverColor,
    iconsColor,
    backAndNextButtonsColor,
    backAndNextButtonsFontColor,
    innerTextOnHover,
    inputsFontColor,
    inputsBackground,
    borderRadiusesForInnerElements,
    borderRadiusesForWholeApp,
    borderColorForInnerElements,
    borderColorForOuterApp,
  } = useContext(ThemeContext)
  const [fontColorState, setFontColorState] = useState(fontColor)
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? "<" : ">"

    return (
      <Button
        onClick={() => {
          onClick()
          // console.log(type)
        }}
        disabled={isEdge}
        isEdge={isEdge}
        inputsBackground={inputsBackground}
        borderColorForInnerElements={borderColorForInnerElements}
        borderRadiusesForInnerElements={borderRadiusesForInnerElements}
        style={{
          color: fontColorState,
        }}
      >
        {pointer}
      </Button>
    )
  }
  const [safetySeat, setSafetySeat] = useState(false)

  const [boosterSeat, setBoosterSeat] = useState(0)
  const [childSafetySeat, setChildSafetySeat] = useState(0)

  const [alignment, setAlignment] = React.useState("web")
  const [AMPM, setAMPM] = React.useState("")

  const [returnAMPM, setReturnAMPM] = React.useState("")

  const handleChangeAMPM = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
      setTimeForDefaultValueAMPM(event.target.textContent)
    }
    setAMPM(event.target.textContent)

    setTimeForDefaultValueAMPM(event.target.textContent)
    // console.log(event.target.textContent)
  }

  const handleChangeReturnAMPM = (event, newAlignment) => {
    if (newAlignment !== null) {
      setReturnTimeAMPM(event.target.textContent)
    }
    setReturnAMPM(event.target.textContent)

    setReturnTimeAMPM(event.target.textContent)
    // console.log(event.target.textContent)
  }

  const [roundTripSwitch, setRoundTripSwitch] = useState(false)
  // console.log(hourlyRedux)

  const onSubmit2 = (data) => {
    const timeNumberAfterColon = time
      .split("")
      .splice(-2)
      // .map((number) => {
      //   return Number(`${number}`)
      // })
      .join("")
    const timeNumberAfterColonRedux = formData.timeForDefaultValue
      .split("")
      .splice(-2)
      // .map((number) => {
      //   return Number(`${number}`)
      // })
      .join("")
    const timeNumberLength = time.split("")
    const timeNumberLengthredux = formData.timeForDefaultValue.split("")
    var timeNumberIsFullZero = time.replace(/[:]/g, "")
    var timeNumberIsFullZeroRedux = formData.timeForDefaultValue.replace(
      /[:]/g,
      ""
    )

    const returnTimeNumberAfterColon = returnTimeLocal
      .split("")
      .splice(-2)
      // .map((number) => {
      //   return Number(`${number}`)
      // })
      .join("")
    // .replace(/[:]/g, "")
    const returnTimeNumberAfterColonRedux = formData.returnTimeValue
      .split("")
      .splice(-2)
      // .map((number) => {
      //   return Number(`${number}`)
      // })
      .join("")
    // .replace(/[:]/g, "")
    const returnTimeNumberLength = returnTimeLocal.split("")
    const returnTimeNumberLengthredux = formData.returnTimeValue.split("")
    var returnTimeNumberIsFullZero = returnTimeLocal.replace(/[:]/g, "")
    var returnTimeNumberIsFullZeroRedux = formData.returnTimeValue.replace(
      /[:]/g,
      ""
    )

    const isRoundTripEnabled = () => {
      if (formData.isRoundTrip) {
        if (
          // (Number(
          //   `${returnTimeNumberAfterColon || returnTimeNumberAfterColonRedux}`
          // ) < 60 &&
          Number(`${returnTimeNumberAfterColonRedux}`) < 60 &&
          // Number(`${returnTimeNumberAfterColonRedux}`) !== 0 &&
          (returnTimeNumberAfterColonRedux !== ":0" ||
            returnTimeNumberAfterColon !== ":0") &&
          (returnTimeNumberLength.length ||
            returnTimeNumberLengthredux.length >= 3) &&
          (returnTimeNumberIsFullZero !== "0000" ||
            returnTimeNumberIsFullZeroRedux !== "0000") &&
          (returnTimeNumberIsFullZero !== "000" ||
            returnTimeNumberIsFullZeroRedux !== "000")
        ) {
          return true
        } else if (!formData.isRoundTrip) {
          return true
        }
      } else if (!formData.isRoundTrip) {
        return true
      }
    }

    // console.log(timeNumberIsFullZero)
    if (
      destinations[0].rideCheckPoint &&
      destinations[1].rideCheckPoint &&
      (date || formData.dateForDefaultValue) &&
      (time || formData.timeForDefaultValue) &&
      Number(`${timeNumberAfterColon || timeNumberAfterColonRedux}`) < 60 &&
      (timeNumberLength.length || timeNumberLengthredux.length >= 3) &&
      (timeNumberIsFullZero !== "0000" ||
        timeNumberIsFullZeroRedux !== "0000") &&
      (timeNumberIsFullZero !== "000" || timeNumberIsFullZeroRedux !== "000") &&
      // carSelectionID &&
      (passengers || formData.passengersQuantityForBackStep) &&
      (AMPM || formData?.timeForDefaultValueAMPM?.ampm) &&
      isHourlyEnabled() &&
      isRoundTripEnabled()
    ) {
      // if (isAirline) {
      //   if (!airlineId) {
      //     setRedBorderOnAirlines(true)
      //   } else {
      //     setRedBorderOnAirlines(false)
      //     return true
      //   }
      // } else {
      return true
      // }
    } else {
      if (!destinations[0].rideCheckPoint) {
        setRedBorderOnSubmit(true)
      } else {
        setRedBorderOnSubmit(false)
      }
      if (!destinations[1].rideCheckPoint) {
        setRedBorderOnSubmit2(true)
      } else {
        setRedBorderOnSubmit2(false)
      }
      if (
        // !new Date(data.orderStartDateTime).toLocaleDateString("en-GB") ||
        !date ||
        !formData.dateForDefaultValue
      ) {
        setRedBorderOnSubmitForDate(true)
      } else {
        setRedBorderOnSubmitForDate(false)
      }
      if (!AMPM && !formData?.timeForDefaultValueAMPM?.ampm) {
        setRedBorderOnSubmitForTime(true)
      } else {
        setRedBorderOnSubmitForTime(false)
      }
      if (
        (timeNumberLength.length !== 0 && timeNumberLength.length < 3) ||
        (timeNumberLengthredux.length !== 0 &&
          timeNumberLengthredux.length <= 3) ||
        timeNumberLength.length <= 3 ||
        timeNumberLengthredux.length <= 3
      ) {
        setRedBorderOnSubmitForTime2(true)
        // console.log("WOOOOOOOOOOOW")
      } else {
        setRedBorderOnSubmitForTime2(false)
      }
      if (
        Number(`${timeNumberAfterColon}`) > 60 ||
        Number(`${timeNumberAfterColonRedux}`) > 60 ||
        // Number(`${timeNumberAfterColon}`) == 0 ||
        timeNumberAfterColon == ":0" ||
        timeNumberAfterColonRedux == ":0"
      ) {
        setRedBorderOnSubmitForTime3(true)
      } else {
        setRedBorderOnSubmitForTime3(false)
      }
      if (
        timeNumberIsFullZero == "0000" ||
        timeNumberIsFullZeroRedux == "0000" ||
        timeNumberIsFullZero == "000" ||
        timeNumberIsFullZeroRedux == "000"
      ) {
        setRedBorderOnSubmitForTime4(true)
      } else {
        setRedBorderOnSubmitForTime4(false)
      }

      if (
        // !new Date(data.returnStartDateTime).toLocaleDateString("en-GB") ||
        !returnDateLocal ||
        !formData.returnDateValue
      ) {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnDate(true)
      } else {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnDate(false)
      }
      if (
        !returnAMPM &&
        !formData?.returnTimeForDefaultValueAMPMalignment?.returnAmpm
      ) {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime(true)
      } else {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime(false)
      }
      if (
        (returnTimeNumberLength.length !== 0 &&
          returnTimeNumberLength.length < 3) ||
        (returnTimeNumberLengthredux.length !== 0 &&
          returnTimeNumberLengthredux.length < 3) ||
        returnTimeNumberLength < 3 ||
        returnTimeNumberLengthredux < 3
      ) {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime2(true)
        // console.log("WOOOOOOOOOOOW")
      } else {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime2(false)
      }
      if (
        Number(`${returnTimeNumberAfterColon}`) > 60 ||
        Number(`${returnTimeNumberAfterColonRedux}`) > 60 ||
        // Number(`${returnTimeNumberAfterColonRedux}`) == 0
        // ||
        returnTimeNumberAfterColon == ":0" ||
        returnTimeNumberAfterColonRedux == ":0"
      ) {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime3(true)
      } else {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime3(false)
      }
      if (
        returnTimeNumberIsFullZero == "0000" ||
        returnTimeNumberIsFullZeroRedux == "0000" ||
        returnTimeNumberIsFullZero == "000" ||
        returnTimeNumberIsFullZeroRedux == "000"
      ) {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime4(true)
      } else {
        formData?.isRoundTrip && setRedBorderOnSubmitForReturnTime4(false)
      }
      // if (carSelectionID) {
      //   setRedBorderOnSubmitForCarType(false)
      // } else {
      //   setRedBorderOnSubmitForCarType(true)
      // }
      if (hourly && !hoursAddressForm) {
        setRedBorderOnSubmitForHours(true)
        // console.log("WOOOOOOOOOOOW")
      } else {
        setRedBorderOnSubmitForHours(false)
      }
      if (formData.passengersQuantityForBackStep) {
        setRedBorderOnSubmitForPassengers(false)
      } else {
        setRedBorderOnSubmitForPassengers(true)
      }
    }
  }

  const onSubmit = (data) => {
    // console.log(data)

    if (onSubmit2(data)) {
      if (Boolean(localStorage.getItem("captcha")) == false) {
        // Boolean(localStorage.getItem("captcha")) !== true &&
        setShowRecaptcha(true)
      } else {
        getCompanyCars(
          {
            hours: hourlyAndSeatsRedux ? hoursAddressForm || formData.hours : 0,
            isGateMeeting: isGateMeeting,
            isReturnGateMeeting: isReturnGateMeeting,
            airlines: { id: airlineId },
            returnAirlines: { id: returnAirlineId },

            orderAddressDetails: [...destinations],
            returnOrderAddressDetails: formData.isRoundTrip
              ? [...returnDestinations]
              : [],
            isRoundTrip: formData.isRoundTrip,
            flightNumber: flightNumber,
            returnFlightNumber: returnFlightNumber,
            page: pageSize,
            // typeId: carSelectionID,
            bookingType: bookingType,
            passengersQuantity: formData.passengersQuantityForBackStep,
            isAirportPickupIncluded: isAirportPickupIncludedLocalState,
            isReturnAirportPickupIncluded:
              isReturnAirportPickupIncludedLocalState,
            boosterSeatCount: boosterSeat || formData.boosterSeatCount,
            safetySeatCount: childSafetySeat || formData.safetySeatCount,
            luggageCount: luggage,
            Tips: {
              AmountType: 2,
              Amount: 18,
            },
          },
          accessKey
        )
        setSafetySeatCount(childSafetySeat)
        setBoosterSeatCount(boosterSeat)
        setDateForDefaultValue(date?.toLocaleDateString("en-US"))

        const forRes = date?.toLocaleDateString("en-US")
        const forRes2 = time + ` ${AMPM}`

        const returnForRes = returnDateLocal?.toLocaleDateString("en-US")
        const returnForRes2 = returnTimeLocal + ` ${returnAMPM}`
        // console.log(
        //   event.target.value.match(/\d+/),
        //   event.target.value.substr(event.target.value.indexOf(":")).match(/\d+/)
        // )
        const returnResData2 = {
          returnStartDateTime: `${returnForRes} ` + `${returnForRes2}`,
        }
        // ._d.toLocaleTimeString("en-US", {
        //   hour: "numeric",
        //   minute: "numeric",
        // })

        // + ` ${AMPM}`
        // const resData = {
        //   orderStartDate: `${forRes}`,
        //   orderStartTime: `${forRes2}`,
        // }
        const resData2 = {
          orderStartDateTime: `${forRes} ` + `${forRes2}`,
        }

        if (returnDateLocal) {
          setFormData(returnResData2)
        }

        if (date) {
          setFormData(resData2)
        }

        // console.log(
        //   destinations[0].rideCheckPoint,
        //   destinations[1].rideCheckPoint,
        //   data?.orderStartDate,
        //   data?.orderStartTime + ` ${AMPM}`,
        //   {
        //     hours: hourly ? hoursAddressForm : 0,
        //     isGateMeeting: isGateMeeting,
        //     airlines: { id: airlineId },
        //     orderAddressDetails: [...destinations],
        //     flightNumber: data.flightNumber,
        //     page: pageSize,
        //     // typeId: carSelectionID,
        //     bookingType: bookingType,
        //     passengersQuantity: passengers,
        //     boosterSeatCount: boosterSeat,
        //   }
        // )
        next()
      }
    }
  }

  React.useEffect(() => {
    console.log(returnAirline)
    if (firstAirline) {
      setIsAirline(true)
      setBookingType(3)
      // fetchAirlines()
      // setDisableHourly(true)
    } else {
      setIsAirline(false)
      setDisableHourly(false)
    }
    if (returnAirline) {
      setIsReturnAirline(true)
      setBookingType(3)
    } else {
      setIsReturnAirline(false)
      setDisableHourly(false)
    }
  }, [firstAirline, returnAirline])
  React.useEffect(() => {
    if (hourly) {
      if (firstAirline || returnAirline) {
        setBookingType(3)
      } else {
        setBookingType(2)
      }

      // setDisableHourly(true)
    } else {
      if (firstAirline || returnAirline) {
        setBookingType(3)
      } else {
        setBookingType(1)
      }
    }
  })

  const debounce = (cb, delay = 250) => {
    let timeout

    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  const [loadingAirlines, setLoadingAirlines] = useState(false)

  async function fetchAirlines(name) {
    setLoadingAirlines(true)
    const data = await placesApi.getAirlines(name)
    setLoadingAirlines(false)
    const res = data.map((airline) => {
      return {
        id: `${airline.id}`,
        name:
          `${airline.code ? airline.code : airline.code3Letter} ` +
          `${airline.name}`,
      }
    })
    // console.log(data)
    setAirlines(res)
  }

  // const [airlineId, setAirlineId] = useState(0)

  fetchAirlines = debounce(fetchAirlines, 300)

  const extractAirlineId = (name) => {
    fetchAirlines(name)

    const res = airlines.find((element, index, array) => {
      return element.name == name
    })
    res ? setAirlineId(res.id) : setAirlineId(null)
    res ? setReturnAirlineId(res.id) : setReturnAirlineId(null)
    res ? setReturnAirlineId(res.id) : setReturnAirlineId(null)
    // res && dispatch(setAirlinesName(res?.name))
    // dispatch(setAirlinesName(name))
    setAirlineNAme(name)
    console.log(name)
    // setAirlineNAme(name)
    // console.log(res)
  }

  return (
    <AdressFormUIComponent
      carTypes={carTypes}
      formData={formData}
      setHourlyRedux={setHourlyRedux}
      setGateMeetingRedux={setGateMeetingRedux}
      setReturnGateMeetingRedux={setReturnGateMeetingRedux}
      gateMeeting={gateMeeting}
      returnGateMeeting={returnGateMeeting}
      hourlyAndSeatsRedux={hourlyAndSeatsRedux}
      resetInputs={resetInputs}
      setDateForDefaultValue={setDateForDefaultValue}
      setTimeForDefaultValue={setTimeForDefaultValue}
      setPassengersQuantityForBackStep={setPassengersQuantityForBackStep}
      isBoosterSeatExistOnBackend={isBoosterSeatExistOnBackend}
      isSafetySeatExistOnBackend={isSafetySeatExistOnBackend}
      airlines={airlines}
      bookingType={bookingType}
      boosterSeat={boosterSeat}
      carSelectionID={carSelectionID}
      childSafetySeat={childSafetySeat}
      destinations={destinations}
      flightNumber={flightNumber}
      returnFlightNumber={returnFlightNumber}
      setReturnFlightNumber={setReturnFlightNumber}
      handleChangeAMPM={handleChangeAMPM}
      handleChangeReturnAMPM={handleChangeReturnAMPM}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      hourly={hourly}
      hoursAddressForm={hoursAddressForm}
      isAirline={isAirline}
      isReturnAirline={isReturnAirline}
      luggage={luggage}
      methods={methods}
      myArrow={myArrow}
      onSubmit={onSubmit}
      passengers={passengers}
      redBorderOnSubmit={redBorderOnSubmit}
      redBorderOnSubmit2={redBorderOnSubmit2}
      redBorderOnSubmitForCarType={redBorderOnSubmitForCarType}
      redBorderOnSubmitForDate={redBorderOnSubmitForDate}
      redBorderOnSubmitForPassengers={redBorderOnSubmitForPassengers}
      redBorderOnSubmitForTime={redBorderOnSubmitForTime}
      redBorderOnSubmitForTime2={redBorderOnSubmitForTime2}
      redBorderOnSubmitForTime3={redBorderOnSubmitForTime3}
      redBorderOnSubmitForTime4={redBorderOnSubmitForTime4}
      redBorderOnSubmitForReturnDate={redBorderOnSubmitForReturnDate}
      redBorderOnSubmitForReturnTime={redBorderOnSubmitForReturnTime}
      redBorderOnSubmitForReturnTime2={redBorderOnSubmitForReturnTime2}
      redBorderOnSubmitForReturnTime3={redBorderOnSubmitForReturnTime3}
      redBorderOnSubmitForReturnTime4={redBorderOnSubmitForReturnTime4}
      safetySeat={safetySeat}
      setAirlineId={setAirlineId}
      setReturnAirlineId={setReturnAirlineId}
      setBoosterSeat={setBoosterSeat}
      setChildSafetySeat={setChildSafetySeat}
      setDestinations={setDestinations}
      setFlightNumber={setFlightNumber}
      setHourly={setHourly}
      setHoursAddressForm={setHoursAddressForm}
      setIsAirportPickupIncludedLocalState={
        setIsAirportPickupIncludedLocalState
      }
      setReturnIsAirportPickupIncludedLocalState={
        setReturnIsAirportPickupIncludedLocalState
      }
      setIsReturnAirportPickupIncludedRedux={
        setIsReturnAirportPickupIncludedRedux
      }
      isGateMeeting={isGateMeeting}
      setIsGateMeeting={setIsGateMeeting}
      isReturnGateMeeting={isReturnGateMeeting}
      setIsReturnGateMeeting={setIsReturnGateMeeting}
      setLuggage={setLuggage}
      setPassengers={setPassengers}
      setSafetySeat={setSafetySeat}
      date={date}
      setDate={setDate}
      returnDateLocal={returnDateLocal}
      setReturnDateLocal={setReturnDateLocal}
      showDatePickerCalendarModal={showDatePickerCalendarModal}
      setShowDatePickerCalendarModal={setShowDatePickerCalendarModal}
      showReturnDatePickerCalendarModal={showReturnDatePickerCalendarModal}
      setShowReturnDatePickerCalendarModal={
        setShowReturnDatePickerCalendarModal
      }
      AMPM={AMPM}
      returnAMPM={returnAMPM}
      setReturnAMPM={setReturnAMPM}
      fetchAirlines={fetchAirlines}
      extractAirlineId={extractAirlineId}
      time={time}
      setTime={setTime}
      returnTimeLocal={returnTimeLocal}
      setReturnTimeLocal={setReturnTimeLocal}
      setShowCarsWithSafetySeat={setShowCarsWithSafetySeat}
      setIsRoundTrip={setIsRoundTrip}
      roundTripSwitch={roundTripSwitch}
      setRoundTripSwitch={setRoundTripSwitch}
      showRecaptcha={showRecaptcha}
      setShowRecaptcha={setShowRecaptcha}
      setSafetySeatCount={setSafetySeatCount}
      setBoosterSeatCount={setBoosterSeatCount}
      redBorderOnSubmitForHours={redBorderOnSubmitForHours}
      setHoursRedux={setHoursRedux}
      hoursCount={hoursCount}
      setUseHookState={setUseHookState}
      handleChangeAddress={handleChangeAddress}
      handleSelect={handleSelect}
      addEndPoint={addEndPoint}
      removeEndPoint={removeEndPoint}
      setDestinationsIcons={setDestinationsIcons}
      getDestinationsIcons={getDestinationsIcons}
      returnDestinations={returnDestinations}
      setReturnDestinations={setReturnDestinations}
      setReturnDate={setReturnDate}
      setReturnTime={setReturnTime}
      setReturnTimeAMPM={setReturnTimeAMPM}
      setReturnTimeAMPMAlignment={setReturnTimeAMPMAlignment}
      loadingAirlines={loadingAirlines}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    carTypes: state.companyProfile.profile.carTypes,
    pageSize: state.cars.pageSize,
    formData: state.formData,
    resetInputs: state.resetWidgetInputs.resetInputs,
    gateMeeting: state.gateMeeting.isGateMeeting,
    returnGateMeeting: state.gateMeeting.isReturnGateMeeting,
    hourlyAndSeatsRedux: state.hourlyAndSeatsRedux.hourlyRedux,
    hoursCount: state.formData.hours,
    isBoosterSeatExistOnBackend:
      state.companyProfile.isBoosterSeatExistOnBackend,
    isSafetySeatExistOnBackend: state.companyProfile.isSafetySeatExistOnBackend,
    accessKey: state.companyProfile.accessKey,
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCmKhi_5V_pulQtm6DFJ8teDZpR9I5hJoM",
  libraries: ["places", "drawing", "geometry"],
})(
  connect(mapStateToProps, {
    getCarsByType,
    getCompanyCars,
    setFormData,
    setHourlyRedux,
    setGateMeetingRedux,
    setReturnGateMeetingRedux,
    setSafetySeatCount,
    setBoosterSeatCount,
    setDateForDefaultValue,
    setTimeForDefaultValue,
    setTimeForDefaultValueAMPM,
    setTimeForDefaultValueAlignment,
    setReturnDate,
    setReturnTime,
    setReturnTimeAMPM,
    setReturnTimeAMPMAlignment,
    setPassengersQuantityForBackStep,
    setShowCarsWithSafetySeat,
    setHoursRedux,
    setIsRoundTrip,
    setIsReturnAirportPickupIncludedRedux,
  })(AdressFormContainerComponent)
)

const Button = styled.button`
  width: 30px;
  height: 100%;
  background: transparent;
  /* color: $font-color; */
  border: none;
  font-size: 19px;
  /* border-radius: $inputs-border-radius; */
  /* border: 1px solid ${(props) => props.borderColorForInnerElements}; */
  border-radius: ${(props) => props.borderRadiusesForInnerElements};
  cursor: pointer;
  transition: 0.2s;
  color: ${(props) => props.fontColor};
  &:hover {
    background: $hover-color;
    color: $inner-text-on-hover;
    transition: 0.2s;
  }
`
