import { formApi, getNewTotalWithGratuityApi } from "../api/api"
import { toggleIsFetching } from "./car-reducer"
import { failMessage, isSuccess } from "./company-profile-reducer"

export const SET_FORM_DATA = "./form-reducer/SET_FORM_DATA"
const SET_NOTE = "./form-reducer/SET_NOTE"
const LOGOUT = "./form-reducer/LOGOUT"
const SET_CAR_ID = "./form-reducer/SET_CAR_ID"
const SET_ORDER_SUM = "./form-reducer/SET_ORDER_SUM"
const SET_PAYMENT_FORM = "./form-reducer/SET_PAYMENT_FORM"
const SET_SAFETY_SEAT_COUNT = "./form-reducer/SET_SAFETY_SEAT_COUNT"
const SET_BOOSTER_SEAT_COUNT = "./form-reducer/SET_BOOSTER_SEAT_COUNT"
const SET_DATE_DEFAULT_VALUE = "./form-reducer/SET_DATE_DEFAULT_VALUE"
const SET_TIME_DEFAULT_VALUE = "./form-reducer/SET_TIME_DEFAULT_VALUE"
const SET_TIME_DEFAULT_VALUE_AMPM = "./form-reducer/SET_TIME_DEFAULT_VALUE_AMPM"
const SET_TIME_DEFAULT_VALUE_ALIGNMENT =
  "./form-reducer/SET_TIME_DEFAULT_VALUE_ALIGNMENT"
const SET_PASSENGERS_QUANTITY_FOR_BACKSTEP =
  "./form-reducer/SET_PASSENGERS_QUANTITY_FOR_BACKSTEP"

const SET_IS_AIRPORT_PICKUP_INCLUDED =
  "./form-reducer/SET_IS_AIRPORT_PICKUP_INCLUDED"

const SET_IS_RETURN_AIRPORT_PICKUP_INCLUDED =
  "./form-reducer/SET_IS_RETURN_AIRPORT_PICKUP_INCLUDED"

const SET_SHOW_CARS_WITH_SAFETY_SEAT =
  "./form-reducer/SET_SHOW_CARS_WITH_SAFETY_SEAT"

const SET_HOURS_COUNT = "./form-reducer/SET_HOURS_COUNT"

const SET_IS_ROUND_TRIP = "./form-reducer/SET_IS_ROUND_TRIP"

const SET_RETURN_DATE_VALUE = "./form-reducer/SET_RETURN_DATE_VALUE"

const SET_RETURN_TIME_VALUE = "./form-reducer/SET_RETURN_TIME_VALUE"

const SET_RETURN_TIME_VALUE_AMPM = "./form-reducer/SET_RETURN_TIME_VALUE_AMPM"

const SET_RETURN_VALUE_ALIGNMENT = "./form-reducer/SET_RETURN_VALUE_ALIGNMENT"

const SET_GRATUITY = "./form-reducer/SET_GRATUITY"

const SET_FEE_AMOUNT = "./form-reducer/SET_FEE_AMOUNT"

const SET_TOTAL_AMOUNT = "./form-reducer/SET_TOTAL_AMOUNT"

const SET_TIPS_PERCENTAGE = "./form-reducer/SET_TIPS_PERCENTAGE"

const SET_TIPS_TO_NULL_BECAUSE_BACKEND_DOESNT_ACCEPT_ZERO =
  "./form-reducer/SET_TIPS_TO_NULL_BECAUSE_BACKEND_DOESNT_ACCEPT_ZERO"

const SET_IS_CUSTOM_TIPS = "./form-reducer/SET_IS_CUSTOM_TIPS"

let initialState = {
  orderType: 3,
  bookingType: 0,
  orderSum: 0,
  orderStartDateTime: "",
  dateForDefaultValue: "",
  timeForDefaultValue: "",
  timeForDefaultValueAMPMalignment: {
    ampm: "",
    alignment: "web",
  },
  passengersQuantity: 0,
  passengersQuantityForBackStep: 0,
  carInfo: {
    id: 0,
  },

  orderAddressDetails: [
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
  ],

  returnOrderAddressDetails: [
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
    {
      rideCheckPoint: "",
      latitude: 0,
      longitude: 0,
      placeType: 0,
      placeId: "",
    },
  ],
  isRoundTrip: false,
  returnStartDateTime: "",
  returnDateValue: "",
  returnTimeValue: "",
  returnTimeForDefaultValueAMPMalignment: {
    returnAmpm: "",
    returnAlignment: "web",
  },

  orderNotes: "",
  hours: 0,
  paymentInfo: {
    cardNumber: "",
    month: null,
    year: null,
    cvc: "",
    amount: 0,
  },
  client: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    zip: "",
    cityId: 0,
    stateId: 0,
  },
  isAirportPickupIncluded: false,
  isReturnAirportPickupIncluded: false,
  flightNumber: "",
  returnFlightNumber: "",
  airlines: {
    id: 0,
  },
  showCarsWithSafetySeat: false,
  safetySeatCount: 0,
  boosterSeatCount: 0,
  tipsAmount: 0,
  feeAmount: 0,
  totalAmount: 0,
  Tips: {
    AmountType: 2,
    Amount: 18,
  },
  isCustomTips: false,
}

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        ...action.dataForm,
      }
    case SET_NOTE:
      return {
        ...state,
        orderNotes: action.note,
      }
    case SET_CAR_ID:
      return {
        ...state,
        carInfo: { id: action.id },
      }
    case SET_ORDER_SUM:
      return {
        ...state,
        orderSum: action.sum,
      }
    case SET_PAYMENT_FORM:
      return {
        ...state,
        paymentInfo: {
          ...action.form.paymentInfo,
          cardNumber: action.cardNumber,
          month: action.date[0],
          year: action.date[1],
        },
        client: {
          ...action.form.client,
          cityId: action.cityId,
          stateId: action.stateId,
        },
      }
    case SET_SAFETY_SEAT_COUNT:
      return {
        ...state,
        safetySeatCount: action.payload,
      }

    case SET_BOOSTER_SEAT_COUNT:
      return {
        ...state,
        boosterSeatCount: action.payload,
      }

    case SET_DATE_DEFAULT_VALUE:
      return {
        ...state,
        dateForDefaultValue: action.payload,
      }
    case SET_TIME_DEFAULT_VALUE:
      return {
        ...state,
        timeForDefaultValue: action.payload,
      }

    case SET_TIME_DEFAULT_VALUE_AMPM:
      return {
        ...state,
        timeForDefaultValueAMPM: { ampm: action.payload },
      }
    case SET_TIME_DEFAULT_VALUE_ALIGNMENT:
      return {
        ...state,
        timeForDefaultValueAMPM: { alignment: action.payload },
      }

    case SET_RETURN_DATE_VALUE:
      return {
        ...state,
        returnDateValue: action.payload,
      }
    case SET_RETURN_TIME_VALUE:
      return {
        ...state,
        returnTimeValue: action.payload,
      }
    case SET_RETURN_TIME_VALUE_AMPM:
      return {
        ...state,
        returnTimeForDefaultValueAMPMalignment: { returnAmpm: action.payload },
      }
    case SET_RETURN_VALUE_ALIGNMENT:
      return {
        ...state,
        returnTimeForDefaultValueAMPMalignment: {
          returnAlignment: action.payload,
        },
      }

    case SET_PASSENGERS_QUANTITY_FOR_BACKSTEP:
      return {
        ...state,
        passengersQuantityForBackStep: action.payload,
      }
    case SET_IS_AIRPORT_PICKUP_INCLUDED:
      return {
        ...state,
        isAirportPickupIncluded: action.payload,
      }

    case SET_IS_RETURN_AIRPORT_PICKUP_INCLUDED:
      return {
        ...state,
        isReturnAirportPickupIncluded: action.payload,
      }

    case SET_SHOW_CARS_WITH_SAFETY_SEAT:
      return {
        ...state,
        showCarsWithSafetySeat: action.payload,
      }
    case SET_IS_ROUND_TRIP:
      return {
        ...state,
        isRoundTrip: action.payload,
      }

    case SET_HOURS_COUNT:
      return {
        ...state,
        hours: action.payload,
      }

    case SET_GRATUITY:
      return {
        ...state,
        tipsAmount: action.payload,
      }

    case SET_FEE_AMOUNT:
      return {
        ...state,
        feeAmount: action.payload,
      }

    case SET_TOTAL_AMOUNT:
      return {
        ...state,
        totalAmount: action.payload,
      }
    case SET_TIPS_PERCENTAGE:
      return {
        ...state,
        Tips: action.payload,
      }
    case SET_TIPS_TO_NULL_BECAUSE_BACKEND_DOESNT_ACCEPT_ZERO:
      return {
        ...state,
        Tips: action.payload,
      }
    case SET_IS_CUSTOM_TIPS:
      return {
        ...state,
        isCustomTips: action.payload,
      }

    default:
      return state
  }
}

export const setFormData = (dataForm) => ({ type: SET_FORM_DATA, dataForm })

export const logOut = () => ({ type: LOGOUT })

export const setNoteRedux = (note) => ({ type: SET_NOTE, note })

export const setCarId = (id) => ({ type: SET_CAR_ID, id })

export const setOrderSum = (sum) => ({ type: SET_ORDER_SUM, sum })

export const setHoursRedux = (count) => ({
  type: SET_HOURS_COUNT,
  payload: count,
})

export const setDateForDefaultValue = (date) => ({
  type: SET_DATE_DEFAULT_VALUE,
  payload: date,
})

export const setTimeForDefaultValue = (time) => ({
  type: SET_TIME_DEFAULT_VALUE,
  payload: time,
})

export const setTimeForDefaultValueAMPM = (ampm) => ({
  type: SET_TIME_DEFAULT_VALUE_AMPM,
  payload: ampm,
})
export const setTimeForDefaultValueAlignment = (alignment) => ({
  type: SET_TIME_DEFAULT_VALUE_ALIGNMENT,
  payload: alignment,
})

export const setReturnDate = (returnDate) => ({
  type: SET_RETURN_DATE_VALUE,
  payload: returnDate,
})

export const setReturnTime = (returnTime) => ({
  type: SET_RETURN_TIME_VALUE,
  payload: returnTime,
})

export const setReturnTimeAMPM = (returnAmpm) => ({
  type: SET_RETURN_TIME_VALUE_AMPM,
  payload: returnAmpm,
})
export const setReturnTimeAMPMAlignment = (returnAlignment) => ({
  type: SET_RETURN_VALUE_ALIGNMENT,
  payload: returnAlignment,
})

export const setPassengersQuantityForBackStep = (quantity) => ({
  type: SET_PASSENGERS_QUANTITY_FOR_BACKSTEP,
  payload: quantity,
})

export const setIsAirportPickupIncluded = (flag) => ({
  type: SET_IS_AIRPORT_PICKUP_INCLUDED,
  payload: flag,
})

export const setIsReturnAirportPickupIncludedActionCreator = (flag) => ({
  type: SET_IS_RETURN_AIRPORT_PICKUP_INCLUDED,
  payload: flag,
})

export const setIsReturnAirportPickupIncludedRedux = (flag) => {
  return (dispatch) => {
    dispatch(setIsReturnAirportPickupIncludedActionCreator(flag))
  }
}

export const setPaymentForm = (form, cityId, stateId, date, cardNumber) => ({
  type: SET_PAYMENT_FORM,
  form,
  cardNumber,
  cityId,
  stateId,
  date,
})

export const setShowCarsWithSafetySeat = (flag) => ({
  type: SET_SHOW_CARS_WITH_SAFETY_SEAT,
  payload: flag,
})

export const setIsRoundTrip = (flag) => ({
  type: SET_IS_ROUND_TRIP,
  payload: flag,
})

export const setSafetySeatCount = (count) => ({
  type: SET_SAFETY_SEAT_COUNT,
  payload: count,
})
export const setBoosterSeatCount = (count) => ({
  type: SET_BOOSTER_SEAT_COUNT,
  payload: count,
})

export const setGratuity = (res) => ({
  type: SET_GRATUITY,
  payload: res,
})

export const setFeeAmount = (res) => ({
  type: SET_FEE_AMOUNT,
  payload: res,
})

export const setTotalAmount = (res) => ({
  type: SET_TOTAL_AMOUNT,
  payload: res,
})

export const setTipsPercentage = (res) => ({
  type: SET_TIPS_PERCENTAGE,
  payload: res,
})

export const setTipsToNullBecauseBackendDoesntAcceptZero = (res) => ({
  type: SET_TIPS_TO_NULL_BECAUSE_BACKEND_DOESNT_ACCEPT_ZERO,
  payload: res,
})

export const setIsCustomTips = (res) => ({
  type: SET_IS_CUSTOM_TIPS,
  payload: res,
})

export const getNewTotalWithGratuity = (gratuityModel) => {
  return async (dispatch) => {
    dispatch(
      gratuityModel?.Tips !== null
        ? setTipsPercentage(gratuityModel?.Tips)
        : setTipsToNullBecauseBackendDoesntAcceptZero(null)
    )
    let response = await getNewTotalWithGratuityApi(gratuityModel)
    console.log(response)
    dispatch(setGratuity(response.data?.tipsAmount))
    dispatch(setFeeAmount(response.data?.feeAmount))
    dispatch(setTotalAmount(response.data?.totalAmount))
  }
}

export const createReservation = (formSummary, accessKey) => {
  return async (dispatch) => {
    dispatch(toggleIsFetching(true))
    let response = await formApi.createReservation(formSummary, accessKey)

    if (response.status === 200) {
      dispatch(isSuccess(true))
    } else {
      dispatch(isSuccess(false))

      dispatch(
        response.data[0]
          ? failMessage(response.data[0])
          : failMessage(response.status)
      )
    }
    dispatch(toggleIsFetching(false))
  }
}

export default formReducer

// ByDistance = 1,
// ByHour = 2,
// AirportTransfer = 3
